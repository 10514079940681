import { NotFoundError, InvalidRequestError } from 'CommonExceptions';
import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import ClipboardCopy from 'Browser/ClipboardCopy';
import createQueryString from 'Browser/createQueryString';
import Location from 'Browser/Location';
import Alert from 'Components/Alert';
import { hook, Hooks } from 'Components/Hooks';
import { Cond } from 'Components/FormComponents';
import MediaElementPlayer from 'Components/MediaElementPlayer';
import LoadingIndicator from 'Components/LoadingIndicator';
import { toggle, show, hide, updateContent } from 'Components/domHelpers';
import { getTimezoneLabel } from 'DateTime';

import Subpage from './Subpage';

import validateTimezone from './Report/validateTimezone';
import validateCdrID from './validateCdrID';

import ModalAlert from './ModalAlert';
import getMediaErrorCode from './getMediaErrorCode';
import { getRecordingInfo } from './ApiGetters';
import getRecordingURLs from './getRecordingURLs';
import { PlaybackInstructions } from './LcmComponents';
import { RecordingDeleteModal, RecordingOptionsModal } from './RecordingModals';
import { BroadcastEnqueueModal } from './Broadcast';

import s from './strings';

const GET_RECORDING_ID = 'getRecording_playback';

export default class ConferenceRecording extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        cdrID: {
          key: 'id',
          validateFunc: validateCdrID,
        },
        recID: {
          defaultVal: 1,
          validateFunc: this.validateUInt,
        },
        timezone: {
          validateFunc: validateTimezone,
        },
      },

      options: {
        back: {
          defaultVal: '',
        },
        fromTab: {
          defaultVal: 'report',
          validateFunc: this.validateEnumStringFactory(['report', 'recordings']),
        },
      }
    };
  }

  init(config) {
    const optionsModal = new RecordingOptionsModal({
      onSuccess: () => this.redispatch(),
    });

    const deleteModal = new RecordingDeleteModal({
      onSuccess: () => this.back(),
    });

    const broadcastEnqueueModal = new BroadcastEnqueueModal({
      ctrl: this.ctrl.broadcastController,
      onComplete: () => this.openSubpage('lcm'),
    });

    const broadcastEnqueueModalDisplay = () => {
      const { cdrID, recID, timezone } = this._params;
      broadcastEnqueueModal.display({ cdrID, recID, timezone });
    };

    const hooks = this.hooks = new Hooks();

    const root = (
      <div class="subpage">
        <div class="subpage-content">
          <Alert type="success" ref={this._alertCopied}>{s.lblCopyLinkSuccess}</Alert>

          <div class="row subpage-header">
            <div class="col-xs-7">
              <div class="btn-toolbar-discrete">
                <a href="#" target="_blank" class="btn btn-primary" ref={this._aDownload}>{s.lblDownload}</a>
                <Cond test={ClipboardCopy.supported}>
                  <button type="button" class="btn btn-primary" onclick={() => this.copyURL()}>{s.lblCopyLink}</button>
                </Cond>
                <button type="button" class="btn btn-primary" onclick={() => optionsModal.display(this._recordingData, this._params.timezone)}>{s.lblRecordingPlaybackOptions}</button>
                <button type="button" class="btn btn-primary" onclick={() => deleteModal.display(this._params.cdrID)}>{s.lblDeleteRecording}</button>

                <button
                  type="button"
                  class="btn btn-primary"
                  use:hook={hooks.show('broadcastAllowed')}
                  onclick={broadcastEnqueueModalDisplay}
                >
                  {s.Broadcast.enqueue}
                </button>

                <button type="button" class="btn btn-primary ml-auto" onclick={() => this.back()}>{s.lblBack}</button>
              </div>
              <div class="info-table-container info-table-container-padded my-2">
                <table>
                  <tbody>
                    <tr>
                      <td>{s.lblID}:</td>
                      <td class="cell-data"><span class="_conferenceIDFormatted"></span></td>
                      <td>{s.lblRecordingPlaybackNumber}:</td>
                      <td class="cell-data"><span class="_recordingNumber"></span></td>
                    </tr>
                    <tr>
                      <td>{s.lblStart}:</td>
                      <td class="cell-data"><span class="_startedDate"></span></td>
                      <td>{s.lblRecordingPlaybackOptions}:</td>
                      <td class="cell-data"><span class="_playBackOptions"></span></td>
                    </tr>
                    <tr>
                      <td>{s.lblEnd}:</td>
                      <td class="cell-data"><span class="_endedDate"></span></td>
                      <td>{s.lblRecordingPlaybackOptionsPasscode}:</td>
                      <td class="cell-data">
                        <span class="_passcode" ref={this._passcodeSpan}></span> <button type="button" class="btn btn-link" onclick={() => this._togglePasscodeVisibility()} ref={this._btnTogglePasscode}>{s.lblShow}</button>
                      </td>
                    </tr>
                    <tr>
                      <td>{s.conferenceRecording.activationDate}:</td>
                      <td class="cell-data"><span class="_activationDate"></span></td>
                      <td>{s.conferenceRecording.expirationDate}:</td>
                      <td class="cell-data"><span class="_expirationDate"></span></td>
                    </tr>
                    <tr>
                      <td>{s.lblTimeZone}:</td>
                      <td class="cell-data" colspan="3"><span class="_timezone"></span></td>
                    </tr>
                    <tr>
                      <td>{s.conferenceRecording.customID}:</td>
                      <td class="cell-data" colspan="3"><span class="_customID"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="media-element-player" ref={this._playerContainer}></div>
              <PlaybackInstructions showEnableInstructions ref={this._instructions} />
            </div>
          </div>
        </div>
      </div>
    );

    super.init(root, {
      defaultBackSubpage: 'report',
    });

    this._recordingPlayer = new MediaElementPlayer(this._playerContainer);
    this._recordingPlayer.title = s.lblRecording;

    this._recordingPlayer
      .on('loaded', () => {
        this._setState('loaded');
      })
      .on('loaderror', e => {
        const { error } = e;

        this._setState('error');
        this.displayError(getMediaErrorCode(error));
      });

    this._loadingIndicator = new LoadingIndicator(this._playerContainer, {
      label: s.lblLoadingRecording,
    });

    const openSettings = () => this.openSubpage('settings');
    this.root.querySelectorAll('.btn-settings')
      .forEach(_ => _.onclick = openSettings);
  }

  activate() {
    return Promise.resolve()
      .then(() => {
        if (!this._params.cdrID) {
          throw new InvalidRequestError();
        }

        this.setFieldDefault('timezone', this.ctrl.timezone);

        const { cdrID, recID, timezone } = this._params;

        return getRecordingInfo({
          cdrID,
          recID,
          timezone,
        });
      })
      .then(result => {
        const rec = result.items && result.items[0];
        if (!rec) {
          throw new NotFoundError();
        }

        this._recordingData = rec;
        this._render(rec);
      })
      .then(() => this._getRecordingURLs())
      .then(urls => {
        this._aDownload.href = urls.downloadURL;
        this._recordingPlayer.load(urls.srcURL);
      })
      .catch(err => {
        if (err instanceof InvalidRequestError) {
          this.displayError('ERR_INVALID_REQUEST', true);
          return;
        }
        if (err instanceof NotFoundError) {
          this.displayError('ERR_API_RECORDING_NOT_FOUND', true);
          return;
        }

        if (err instanceof ApiErrorResult) {
          const { code } = err;

          if (code === 'ERR_API_RECORDING_NOT_FOUND' || code === 'ERR_API_RECORDING_UNAVAILABLE') {
            hide(this._aDownload);
            this._setState('error');
            this.displayError(code);
            return;
          }
        }

        throw err;
      });
  }

  detach() {
    this._recordingPlayer.unload();
  }

  _getRecordingURLs() {
    const { cdrID, recID, timezone } = this._params;

    const params = {
      cdrID,
      recID,
      timezone,
      dateFormat: 'shortDateTime12Hr',
    };

    const checkParams = {
      ...params,
      checkOnlyFlag: true,
    };

    return Api.get('CDR', 'getRecording', checkParams, { cancelID: GET_RECORDING_ID })
      .then(result => {
        const recording = result.conferenceRecording;
        if (!recording) {
          throw new Error('conferenceRecording missing in result');
        }

        return getRecordingURLs(recording.url, 'CDR', 'getRecording', params);
      });
  }

  _setState(state) {
    toggle(this._playerContainer, state !== 'error');

    if (state === 'loading') {
      this._loadingIndicator.show();
    } else {
      this._loadingIndicator.hide();
    }
  }

  _render(data) {
    this.hooks.run(this.ctrl);

    hide(this._alertCopied);
    show(this._aDownload);

    this._setState('loading');

    const {
      playBackSelected, playBackDefault,
      recordingNumber, passcode,
      activationDate,
      expirationDate,
    } = data;

    let playBackOptions;
    if (!playBackSelected && !playBackDefault) {
      playBackOptions = s.lblNone;
    } else if (playBackDefault) {
      playBackOptions = s.lblRecordingPlaybackOptionsDefault;
    } else {
      playBackOptions = s.lblRecordingPlaybackOptionsSelected;
    }

    const passcodeSet = passcode !== null;

    updateContent(this.root, {
      ...data,
      timezone: getTimezoneLabel(this._params.timezone),
      playBackOptions,
      recordingNumber: recordingNumber !== null
        ? recordingNumber
        : s.lblNone,
      passcode: passcodeSet
        ? passcode
        : s.lblNone,
      activationDate: activationDate !== null
        ? activationDate
        : s.conferenceRecording.now,
      expirationDate: expirationDate !== null
        ? expirationDate
        : s.conferenceRecording.never,
    });

    toggle(this._btnTogglePasscode, passcodeSet);
    this._setPasscodeVisibility(!passcodeSet);

    this._instructions.render(data);
  }

  _togglePasscodeVisibility() {
    this._setPasscodeVisibility(!this._passcodeVisibility);
  }

  _setPasscodeVisibility(flag) {
    this._passcodeVisibility = flag;

    toggle(this._passcodeSpan, flag);
    this._btnTogglePasscode.textContent = flag
      ? s.lblHide
      : s.lblShow;
  }

  copyURL() {
    hide(this._alertCopied);

    new ClipboardCopy({
      container: this.root,
      text: this._getPlayerURL(),
      resultCallback: succeeded => {
        if (succeeded) {
          show(this._alertCopied);
        } else {
          ModalAlert.display('ERR_COPY_URL_FAILED');
        }
      }
    });
  }

  _getPlayerURL() {
    const { cdrID, accountID, recID, s } = this._recordingData;
    const qs = createQueryString({
      cdrID,
      accountID,
      recID,
      s,
    });

    return `${Location.baseURL}recording.html?${qs}`;
  }

  getActiveTabKey() {
    return this._options.fromTab;
  }
}
