import Api from 'Api/Api';
import { Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';

import ModalAlert from './ModalAlert';
import ModalConfirmDelete from './ModalConfirmDelete';
import Subpage from './Subpage';
import { initPaging, updatePaging } from './SubpagePaging';
import { SortableTable, Sorter } from './Tables';
import { PagingToolbar } from './Paging';
import { TableToolbar, SubpageSearchInput } from './LcmComponents';
import MakeCallModal from './MakeCallModal';
import appErrorHandler from './appErrorHandler';
import s from './strings';

export default class AddressBook extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        search: {
          defaultVal:  '',
        },
      },

      sortColumnDefault: 'name',
      sortColumns: [
        'phoneNumber', 'name', 'blockedFlag', 'hostFlag'
      ],

      options: {
      }
    };

    this.pagingController = initPaging(this);

    this._modalDelete = new ModalConfirmDelete({
      title: s.lblDialogTitleDeleteAddressBookEntry,
      messageTextPost: s.lblAreYouSureDeleteAddressBookPost,

      onConfirm: params => {
        Api.get('Bridge', 'deleteAddressBookEntry', params)
          .then(() => this.redispatch())
          .catch(err => {
            ModalAlert.display(appErrorHandler(err));
          });
      },
    });
  }

  init(config) {
    const hooks = this.hooks = new Hooks();

    const ctx = {
      hooks,
      ctrl: this,
    };

    const root = (
      <div class="subpage subpage-crudlist">
        <div class="subpage-content">
          <TableToolbar>
            <button type="button" class="btn btn-primary" onclick={() => this._openEntry()}>{s.lblAdd}</button>

            <SubpageSearchInput ctx={ctx} />
          </TableToolbar>

          <AddressBookTable ref={this._table} onSort={e => this._onSort(e)} onCellButtonClick={e => this._onCellButtonClick(e)} />
          <PagingToolbar ctrl={this.pagingController} />
        </div>
      </div>
    );

    super.init(root, {
      enableSortingParams: true,
    });

    this._makeCallModal = new MakeCallModal(this.ctrl, this.opCtrl);
  }

  activate() {
    return Promise.resolve()
      .then(() => Api.get('Bridge', 'getAddressBook', {
        ...this.getStateValues([
          'startOffset', 'resultCount', 'search', 'sortColumn', 'sortDirection'
        ]),
      }))
      .then(result => {
        this._result = result;
        this._result.items = result.addressBookEntry || [];

        if (!updatePaging(this, result.totalResults)) {
          return;
        }

        this.hooks.run({
          formData: {
            search: this._params.search,
          },
        });

        this.render();
      });
  }

  render() {
    const { items } = this._result;
    const { makeCallAllowed } = this.ctrl;
    const extra = {
      makeCallAllowed,
    };

    this._table.clear();
    this._table.render(items, extra, this.sortProps);
  }

  _onSort({ sortColumn, sortDirection }) {
    this.changeSort(sortColumn, sortDirection);
  }

  _onCellButtonClick({ key: entryID, itemIdx, colId }) {
    const item = this._result.items[itemIdx];

    switch (colId) {
    case 'makeCall':
      this._makeCallModal.show(item);
      break;

    case 'edit':
      this._openEntry(entryID);
      break;

    case 'delete':
      this._modalDelete.display({ entryID }, item.phoneNumber);
      break;
    }
  }

  _openEntry(entryID = null) {
    this.openSubpage('addressBookEntry', {
      ...(entryID && { id: entryID }),
      back: this.getCanonicalHash(),
    });
  }
}

class AddressBookTable extends SortableTable {
  constructor({ ref, onSort, onCellButtonClick }) {
    super({
      ref,
      onCellButtonClick,
      className: 'nowrap striped dataTable subpage-table',
      itemKey: 'entryID',
      noDataString: s.lblNoData,
      onHeaderClick: e => {
        this._sorter.onHeaderClick(e);

        const [ sortProp ] = this._sorter.getSortProps();

        onSort({
          sortColumn: sortProp.colId,
          sortDirection: sortProp.order,
        });
      },
      columns: [
        {
          id: 'phoneNumber',
          colKey: 'phoneNumber',
          title: s.lblNumber,
          className: 'shrink',
          sorting: true,
        },
        {
          colKey: 'ext',
          title: s.lblExt,
          className: 'shrink',
        },
        {
          id: 'name',
          colKey: 'name',
          title: s.lblAssignedName,
          className: 'column-name',
          sorting: true,
        },
        {
          id: 'blockedFlag',
          colKey: 'blockedFlag',
          title: s.lblBlock,
          className: 'shrink',
          sorting: true,
          create(cell) {
            return cell ? s.lblYes : '';
          },
        },
        {
          id: 'hostFlag',
          colKey: 'hostFlag',
          title: s.lblHost,
          className: 'shrink',
          sorting: true,
          create(cell) {
            return cell ? s.lblYes : '';
          },
        },
        {
          id: 'makeCall',
          colKey: 'blockedFlag',
          className: 'hover-cell shrink',
          visibility: ({ makeCallAllowed }) => makeCallAllowed,
          create(cell) {
            // don't allow makeCall for blocked addressBookEntry
            if (cell) return '';

            return <button type="button" class="btn-table-icon" title={s.lblDialOut}>{TbIcons.MAKE_CALL}</button>;
          },
        },
        {
          id: 'edit',
          className: 'hover-cell shrink',
          create() {
            return <button type="button" class="btn-table-icon" title={s.lblEdit}>{TbIcons.EDIT}</button>;
          },
        },
        {
          id: 'delete',
          className: 'hover-cell shrink',
          create() {
            return <button type="button" class="btn-table-icon" title={s.lblDelete}>{TbIcons.TRASH}</button>;
          },
        },
      ],
    });

    this._sorter = new Sorter({
      table: this,
    });
  }

  render(data, extra, sortProps) {
    this._sorter.setSortProps(sortProps);
    this.renderSort(this._sorter.getSortProps());
    super.render(data, extra);
  }
}
