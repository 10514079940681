import { clamp } from 'MathUtil';

import { hide, show } from 'Components/domHelpers';
import { focusFirstDescendant, trapFocusFactory } from 'Components/FocusUtils';
import { Cond } from 'Components/FormComponents';

// Constraint Types:
// default:
//   desktop-like behavior. keep at least a small portion of the
//   object visible when dragging out of bounds
//
// contain:
//   don't allow any portion of the object to leave the boundaries


// minimum number of pixels that must remain on the screen when
// dragging modal out of the viewport. only used for default
// constraintType
const CONSTRAINT_MARGIN = 10;

const BACKDROP_Z_INDEX = 1040;

class ModalManager {
  static openModal(modal) {
    let prevModal = null;

    if (this._activeModals.length) {
      // bury topmost modal if there is one
      prevModal = this._activeModals[this._activeModals.length - 1];
      prevModal.bury();
    }

    this._activeModals.push(modal);

    if (modal.useOverlay)
      show(this.overlay);

    modal.root.setAttribute('aria-hidden', 'false');

    if (!modal.anchored) {
      // position modal
      if (prevModal) {
        const pos = prevModal.getPosition();
        const headerHeight = prevModal.getHeaderHeight();

        modal.setPosition(
          pos.top + headerHeight,
          pos.left + headerHeight
        );
      } else {
        modal.setPositionCenter();
      }

      this._setScrollbar(modal.root);
      document.body.classList.add('modal-open');
    }

    this._setModalZIndexes();

    modal.unbury();
  }

  static closeModal(modal) {
    const curIdx = this._activeModals.indexOf(modal);
    if (curIdx < 0) {
      throw new Error('modal not found');
    }

    modal.bury();
    modal.root.setAttribute('aria-hidden', 'true');

    // remove modal from list
    this._activeModals.splice(curIdx, 1);

    this._setModalZIndexes();

    if (this._activeModals.length) {
      this._activeModals[this._activeModals.length - 1].unbury();
    } else {
      hide(this.overlay);
      document.body.classList.remove('modal-open');
      this._resetScrollbar(modal.root);
    }
  }

  static _activeModals = [];

  static get overlay() {
    if (!this._overlay) {
      this._overlay = <div class="modal-backdrop" />;
      document.body.appendChild(this._overlay);
    }
    return this._overlay;
  }

  static _setModalZIndexes() {
    if (!this._activeModals.length)
      return;

    const lastIdx = this._activeModals.length - 1;

    // set z-index for all but topmost modal
    for (let i = 0; i < lastIdx; i++) {
      const modal = this._activeModals[i];

      modal.root.style.zIndex = BACKDROP_Z_INDEX - (lastIdx - i);
    }

    // clear z-index override for topmost modal
    this._activeModals[lastIdx].root.style.zIndex = '';
  }

  static getScrollbarAdjustment() {
    return this._scrollbarAdjustment;
  }

  static setScrollbarAdjustment(enabled) {
    this._scrollbarAdjustment = !!enabled;
  }

  static _scrollbarAdjustment = true;

  static _setScrollbar(modalRoot) {
    if (!this._scrollbarAdjustment) {
      return;
    }

    const bodyPad = parseInt(getComputedStyle(document.body).paddingRight, 10);
    const bodyOverflow = document.documentElement.clientHeight !== document.documentElement.scrollHeight;
    const modalOverflow = modalRoot.clientHeight !== modalRoot.scrollHeight;

    const scrollBarWidth = this._measureScrollbar();

    document.body.style.paddingRight = modalOverflow || bodyOverflow ? `${bodyPad + scrollBarWidth}px` : '';
  }

  static _resetScrollbar(modalRoot) {
    if (!this._scrollbarAdjustment) {
      return;
    }

    document.body.style.paddingRight = '';
    modalRoot.style.paddingRight = '';
  }

  static _measureScrollbar() {
    return Math.abs(window.innerWidth - document.documentElement.clientWidth);
  }
}

export default class Modal {
  static isClassComponent = true;

  constructor(options) {
    const {
      ref = null,
      header = true,
      panel = true,

      anchored = false,
      useOverlay = true,

      focusFirst = true,
    } = options;

    if (ref)
      ref(this);

    this._constraintType = 'default';
    this._isOpen = false;
    this.anchored = anchored;
    this.useOverlay = useOverlay;
    this._focusFirst = focusFirst;
    this.draggable = !anchored;

    const onClose = options.onClose || (() => this.hide());

    this.root = (
      <div class={!this.anchored ? 'modal' : 'modal-anchored'} role="dialog">
        <div class="modal-dialog" role="document" ref={this.dialog}>
          <div class="modal-content" class:modal-content-panel={panel}>
            <Cond test={header}>
              <div class="modal-header" ref={this.header}>
                <span class="modal-title" ref={this._title} />

                <button
                  type="button"
                  class="modal-close"
                  aria-label="Close"
                  onclick={onClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </Cond>
            <div ref={this.bodyAndFooter}>
              {options.children}
            </div>
          </div>
        </div>
      </div>
    );

    if (options.appendToBody) {
      document.body.appendChild(this.root);
    }

    if (this.draggable) {
      this.root.classList.add('modal-draggable');
    }

    if (options.title) {
      this.title = options.title;
    }

    if (options.dialogClass) {
      this.dialog.className += ` ${options.dialogClass}`;
    }

    if (options.small) {
      this.dialog.classList.add('modal-sm');
    }

    this._setupDragEvents(this.dialog, this.header);
    this._trapFocus = trapFocusFactory(this.bodyAndFooter);
  }

  show() {
    if (this._isOpen)
      return;

    this._isOpen = true;

    ModalManager.openModal(this);
  }

  hide() {
    if (!this._isOpen)
      return;

    this._isOpen = false;

    if (this._onhide) {
      this._onhide();
    }

    ModalManager.closeModal(this);
  }

  unbury() {
    this._addListeners();

    this.focusFirstDescendant();
  }

  bury() {
    this._removeListeners();
  }

  focusFirstDescendant() {
    if (!this._focusFirst || !focusFirstDescendant(this.bodyAndFooter)) {
      if (document.activeElement && document.activeElement.blur)
        document.activeElement.blur();
    }
  }

  getPosition() {
    return {
      top: this.dialog.offsetTop,
      left: this.dialog.offsetLeft,
    };
  }

  setPosition(top, left) {
    this.dialog.style.top = `${top}px`;
    this.dialog.style.left = `${left}px`;
  }

  setPositionCenter() {
    const top = window.innerHeight * 0.1;
    const left = (window.innerWidth - this.dialog.clientWidth) / 2;
    this.setPosition(top, left);
  }

  getHeaderHeight() {
    return this.header.clientHeight;
  }

  get isOpen() {
    return this._isOpen;
  }

  set title(val) {
    if (this._title)
      this._title.textContent = val;
  }

  static getScrollbarAdjustment() {
    return ModalManager.getScrollbarAdjustment();
  }

  static setScrollbarAdjustment(enabled) {
    ModalManager.setScrollbarAdjustment(enabled);
  }

  _addListeners() {
    document.addEventListener('focus', this._trapFocus, true);
    if (this.draggable) {
      this._dragHandle.addEventListener('mousedown', this._dragListener);
    }
  }

  _removeListeners() {
    document.removeEventListener('focus', this._trapFocus, true);
    if (this.draggable) {
      this._dragHandle.removeEventListener('mousedown', this._dragListener);
    }
  }

  _setupDragEvents(element, handle) {
    this._dragHandle = handle || element;

    this._dragListener = e => {
      // left click only
      if (e.which !== 1) {
        return true;
      }

      // ignore mousedown if it is not directly on this._dragHandle or
      // modal-title
      if (!(e.target === this._dragHandle || e.target === this._title)) {
        return true;
      }

      e.preventDefault();

      let { pageX: startX, pageY: startY } = e;

      let { left: startLeft, top: startTop } = element.style;
      startLeft = parseFloat(startLeft) || 0;
      startTop = parseFloat(startTop) || 0;

      const moved = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { pageX: curX, pageY: curY } = e;

        const {
          minLeft,
          maxLeft,
          minTop,
          maxTop,
        } = this._getDragConstraints(element, startLeft, startTop, startX, startY);

        const newLeft = clamp(curX - startX + startLeft, minLeft, maxLeft);
        const newTop = clamp(curY - startY + startTop, minTop, maxTop);

        element.style.left = `${newLeft}px`;
        element.style.top = `${newTop}px`;
      };

      const cleanup = () => {
        document.removeEventListener('mousemove', moved);
        document.removeEventListener('mouseup', cleanup);
        this._onhide = null;

        document.body.style.cursor = '';
      };

      document.addEventListener('mousemove', moved);
      document.addEventListener('mouseup', cleanup);
      this._onhide = cleanup;

      document.body.style.cursor = 'move';
    };
  }

  _getDragConstraints(element, startLeft, startTop, startX, startY) {
    if (this._constraintType === 'default') {
      return this._getConstraints(element, startLeft, startTop, startX, startY, 0, 0, CONSTRAINT_MARGIN);
    } else {
      let { left: curLeft, top: curTop } = element.style;
      curLeft = parseFloat(curLeft) || 0;
      curTop = parseFloat(curTop) || 0;

      const rect = element.getBoundingClientRect();

      return this._getConstraints(element, curLeft, curTop, rect.left, rect.top, rect.width, rect.height);
    }
  }

  _getConstraints(element, originLeft, originTop, originX, originY, originWidth, originHeight, margin = 0) {
    const offsetX = originLeft - originX;
    const offsetY = originTop - originY;

    return {
      minLeft: offsetX + margin,
      maxLeft: offsetX + window.innerWidth - originWidth - margin,
      minTop: offsetY + margin,
      maxTop: offsetY + window.innerHeight - originHeight - margin,
    };
  }
}
