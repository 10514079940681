export default function parseQueryString(str) {
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = s => decodeURIComponent(s.replace(/\+/g, ' '));
  const ret = {};

  let match;
  while ((match = search.exec(str)))
    ret[decode(match[1])] = decode(match[2]);

  return ret;
}
