import { toggleClass } from 'Components/domHelpers';

const TAB_ACTIVE_CLASS = 'tb-tabs-active';

export function TabsContainer({ children, ...props }) {
  return (
    <nav class="tb-tabs-container" {...props}>
      {children}
    </nav>
  );
}

export class Tabs {
  static isClassComponent = true;

  constructor({ tabs = [], ref }) {
    if (ref) {
      ref(this);
    }

    this._tabs = [];
    this._tabsKeyed = {};

    this.root = (
      <ul class="tb-tabs" />
    );

    if (tabs.length) this.setTabs(tabs);
  }

  setTabs(tabs) {
    this._tabs = [];
    this._tabsKeyed = {};

    tabs.map(tab => this.addTab(tab));
  }

  setActive(key) {
    this._tabs.forEach(tab => {
      toggleClass(tab.li, TAB_ACTIVE_CLASS, tab.key === key);
    });
  }

  addTab(tab, relativeKey = null, before = false) {
    const li = this._addTab(tab);

    if (relativeKey) {
      this._tabsKeyed[relativeKey].li[before ? 'before' : 'after'](li);
    } else {
      this.root[before ? 'prepend' : 'append'](li);
    }
  }

  _addTab(tab) {
    const { key, label, target, href = '#', cssClass = null } = tab;

    this._tabs.push(tab);
    if (key) {
      this._tabsKeyed[key] = tab;
    }

    return (
      <li classList={{...(cssClass && { [cssClass]: true })}} ref={tab.li}>
        <a href={href} target={target} onclick={e => this._onclick(e, tab)}>{label}</a>
      </li>
    );
  }

  removeTab(key) {
    const tab = this._tabsKeyed[key];
    if (!tab) return;

    tab.li.remove();

    delete this._tabsKeyed[key];

    const index = this._tabs.indexOf(tab);
    if (index !== -1)
      this._tabs.splice(index, 1);
  }

  _onclick(e, tab) {
    e.currentTarget.blur();

    if (tab.isLink) return;

    e.preventDefault();

    if (tab.onclick) {
      tab.onclick();
      return;
    }
  }
}
