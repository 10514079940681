import $ from 'jquery';
import 'bootstrap-datepicker';

import { hide, show } from 'Components/domHelpers';
import { FormField, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import MediaElementController from 'Components/MediaElementController';
import Modal from 'Components/Modal';
import RangeInput from 'Components/RangeInput';
import TbIcons from 'Components/TbIcons';

import * as C from './ConfConstants';
import ModalAlert from './ModalAlert';
import { PagingToolbar } from './Paging';

import appErrorHandler from './appErrorHandler';
import s from './strings';


export function LoginInfo({ hooks }) {
  return (
    <div class="login-info rw-only">
      {s.lblLastLogin}
      {' '}
      <span use:hook={hooks.textContent('lastLoginIP')} />
      {' '}
      <span use:hook={hooks.textContent('lastLoginDateLocal')} />
    </div>
  );
}

export class LcmActionPanel {
  static isClassComponent = true;

  constructor({ buttonLabel, onClick, msg = null, ref = null }) {
    if (ref) {
      ref(this);
    }

    this.root = (
      <div class="lcm-action-panel alert alert-success alert-icon rw-only">
        <span ref={this._msg}></span>
        <button type="button" class="btn btn-primary" onclick={onClick}>
          {buttonLabel}
        </button>
      </div>
    );

    if (msg)
      this.setMsg(msg);
  }

  setMsg(msg) {
    this._msg.textContent = msg;
  }
}

export function ToggleButton({ iconType = 'checkbox', className = null, checked = false, indeterminate = false, disabled = false, onclick = null, ref }) {
  let fullClass = 'btn-toggle';
  if (className)
    fullClass += ` ${className}`;

  return <button type="button" class={fullClass} data-icon-type={iconType} data-checked={!!checked || null} data-indeterminate={!!indeterminate || null} disabled={disabled} onclick={onclick} ref={ref} />;
}

export function TableToolbar({ children }) {
  return (
    <div class="table-toolbar btn-toolbar-discrete form-inline">
      {children}
    </div>
  );
}

export function SubpageSearchInput({ ctx: { hooks, ctrl } }) {
  return (
    <SearchInput hooks={hooks} hookProp="formData" dataProp="search" onSubmit={search => ctrl.onSearch(search)} />
  );
}

export function SearchInput({ hooks, hookProp, dataProp, onSubmit }) {
  let input;

  hooks.add(hookProp, data => {
    input.value = data[dataProp];
  });

  return (
    <form
      class="form-group"
      onsubmit={wrapSubmitHandler(() => onSubmit(input.value))}
    >
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          name="search"
          aria-label={s.lblSearch}
          ref={input}
        />
        <span class="input-group-btn">
          <button type="submit" class="btn btn-primary tbicon">
            {TbIcons.SEARCH}
          </button>
        </span>
      </div>
    </form>
  );
}

export function RecordingButton({ passcode, playBackSelected, playBackDefault }) {
  let selectionIcon = TbIcons.BLANK;
  if (playBackDefault) {
    selectionIcon = TbIcons.STAR_CHECKED;
  } else if (playBackSelected) {
    selectionIcon = TbIcons.CHECK;
  }

  return (
    <button type="button" class="btn-table-icon btn-recording" title={s.lblRecording} data-is-default={playBackDefault || null}>
      <span class="btn-recording-inner">
        {playBackSelected || playBackDefault ? TbIcons.PLAY_CIRCLE_INVERSE : TbIcons.PLAY_CIRCLE}
        <div class="playback-options">
          <div>{selectionIcon}</div>
          <div>{passcode ? TbIcons.LOCK : TbIcons.BLANK}</div>
        </div>
      </span>
    </button>
  );
}

const DATEPICKER_OPTIONS = {
  format         : 'yyyy-mm-dd',
  maxViewMode    : 0,
  autoclose      : true,
  todayHighlight : true,
  clearBtn       : true
};

function DatePickerElement(props) {
  const { type = 'text', id, value = '', attributes = {} } = props;

  return (
    <input class="form-control" type={type} id={id} value={value} {...attributes} ref={props.ref}/>
  );
}

export function DatePicker(props) {
  const { form, inline = false, name, rootClass, label, value = '', inputAttributes, type = 'text', trim = true } = props;
  const id = form.getFieldId(name);

  let root;
  let input;
  const register = el => {
    input = el;
    $(el).datepicker(DATEPICKER_OPTIONS);

    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        const { value } = el;
        return trim
          ? value.trim()
          : value;
      },
      setValue(val) {
        el.value = val;
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} ref={root}>
      <div class="input-group">
        <DatePickerElement type={type} id={id} value={value} attributes={inputAttributes} ref={register} />
        <span class="input-group-btn btn-datepicker">
          <button type="button" class="btn btn-primary" onclick={() => $(input).datepicker('show')}></button>
        </span>
      </div>
    </FormField>
  );
}

export class PlaybackInstructions {
  static isClassComponent = true;

  constructor({ ref, showEnableInstructions = false }) {
    ref(this);

    this._showEnableInstructions = showEnableInstructions;
    this._refs = {};

    this.root = (
      <div class="mt-4">
        <div ref={this._refs.enablePlayBack} innerHTML={s.PlayBackInst.enable} />
        <div ref={this._refs.dialConfID} innerHTML={s.PlayBackInst.dialConfID} />
        <div ref={this._refs.dialConfIDRecNumber} innerHTML={s.PlayBackInst.dialConfIDRecNumber} />
        <div ref={this._refs.makeDefault} innerHTML={s.PlayBackInst.makeDefault} />
        <div ref={this._refs.makeSelected} innerHTML={s.PlayBackInst.makeSelected} />
      </div>
    );
  }

  render(data) {
    hide(this.root);

    // hide all instructions elements
    Object.keys(this._refs)
      .forEach(_ => hide(this._refs[_]));

    const {
      recordingNumber,
      playBackNumber,
      playBackNumberFormatted,

      playBackMode,
      playBackRecordingID,
      playBackDefault,
      playBackSelected,
    } = data;

    if (recordingNumber !== null && playBackNumberFormatted !== null) {
      if (playBackMode === null && this._showEnableInstructions) {
        show(this._refs.enablePlayBack);
      } else if (playBackMode === 'all') {
        show(this._refs.dialConfIDRecNumber);
      } else if (playBackMode === 'selected') {
        if (playBackRecordingID !== null) {
          if (playBackDefault) {
            show(this._refs.dialConfID);
          } else if (this._showEnableInstructions) {
            show(this._refs.makeDefault);
          }
        } else {
          if (playBackSelected) {
            show(this._refs.dialConfIDRecNumber);
          } else if (this._showEnableInstructions) {
            show(this._refs.makeSelected);
          }
        }
      }

      this.root.querySelectorAll('._playBackNumberFormatted')
        .forEach(_ => _.href = `tel:${playBackNumber}`);

      show(this.root);
    }
  }
}

export function PlayStopButton({ uri, cssClass = 'btn btn-primary tbicon', playIcon = TbIcons.PLAY, stopIcon = TbIcons.STOP }) {
  const hooks = new Hooks();

  const state = {
    isPlaying: false,
  };

  const update = () => hooks.run(state);

  const onclick = e => {
    const shouldPlay = !state.isPlaying;

    MediaElementController
      .init()
      .stop()
      .then(() => {
        if (shouldPlay) {
          state.isPlaying = true;
          update();
        }

        return typeof uri === 'function'
          ? uri(shouldPlay)
          : uri;
      })
      .then(playUri => {
        if (shouldPlay && playUri) {
          return MediaElementController.play(playUri, s.lblRecording);
        }
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        ModalAlert.display(appErrorHandler(err));
      })
      .then(() => {
        state.isPlaying = false;
        update();
      });
  };

  const root = (
    <button
      type="button"
      class={cssClass}
      use:hook={hooks.attr('isPlaying', 'title', val => val ? s.PlayStopButton.stop : s.PlayStopButton.play)}
      use:hook={hooks.text('isPlaying', val => val ? stopIcon : playIcon)}
      onclick={onclick}
    />
  );

  update();

  return root;
}

export function PanelDismissButton({ onclick }) {
  return (
    <button type="button" class="close" aria-label={s.lblClose} onclick={onclick}><span aria-hidden="true">&times;</span></button>
  );
}

export function PagingPrintHeader({ ctrl }) {
  return (
    <div class="print-header-overlay">
      <div class="print-header-overlay-inner">
        <div>
          <button type="button" class="btn btn-primary btn-print-confirm">{s.lblPrint}</button>
          <button type="button" class="btn btn-primary btn-print-cancel">{s.lblCancel}</button>
        </div>

        <PagingToolbar ctrl={ctrl} />
      </div>
    </div>
  );
}

export function PagingPrintFooter({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('update', () => hooks.run(ctrl));

  return (
    <div class="paging-control-print-footer">
      {s.lblPageLabelStart}
      {' '}
      <span use:hook={hooks.text('page')} />
      {' '}
      {s.lblPageLabelDelim}
      {' '}
      <span use:hook={hooks.text('totalPages')} />
    </div>
  );
}

export class ModalGain {
  static isClassComponent = true;

  constructor({ ctx: { ctrl }, ref }) {
    ref(this);

    this.ctrl = ctrl;

    this.root = (
      <Modal
        title={s.CallListMenu.setGain}
        small
        onClose={() => this.hide()}
        ref={this._modal}
      >
        <div class="modal-body">
          <div class="d-flex align-items-center">
            <span class="tbicon-volume-up mr-1" />
            <RangeInput
              min={C.GAIN_MIN}
              max={C.GAIN_MAX}
              step={1}
              ref={this._input}
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" onclick={() => this.hide()}>{s.lblOK}</button>
        </div>
      </Modal>
    );

    this._input.on('change', () => ctrl.setInGain(this._observer.participantID, this._input.value));
  }

  show(participantID) {
    this._observer = this.ctrl.createCallObserver(participantID, {
      onUpdate: call => this._update(call),
      onSubConfChange: () => {
        this.hide();
        ModalAlert.display('ERR_CALL_SUB_CONF_CHANGED');
      },
      onDisconnected: () => {
        this.hide();
        ModalAlert.display('ERR_CALL_DISCONNECTED');
      },
    });
    this._update(this._observer);

    this._modal.show();
  }

  hide() {
    this._modal.hide();

    this._observer.destroy();
    this._observer = null;
  }

  _update(call) {
    if (call.inGain !== this._input.value) {
      this._input.value = call.inGain;
    }
  }
}
