import { hook, Hooks } from 'Components/Hooks';
import s from './strings';

export function OpStatus({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('update', () => hooks.run(ctrl));

  return (
    <div
      class="operator-status info-table-container"
      use:hook={hooks.show('isOperator')}
    >
      <table>
        <tbody>
          <tr>
            <td>{s.Op.loginCode}:</td>
            <td class="cell-data" use:hook={hooks.text('loginCode')} />
          </tr>
          <tr>
            <td
              colspan="2"
              use:hook={hooks.text('opState', val => s.OP_STATE[val])}
            />
          </tr>
        </tbody>
      </table>

      <div class="btn-toolbar" use:hook={hooks.show('showConnectToConf')}>
        <button
          type="button"
          class="btn btn-primary"
          onclick={() => ctrl.connectToConf()}
        >
          {s.Op.connect}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          onclick={() => ctrl.connectToConf(true)}
        >
          {s.Op.connectListen}
        </button>
      </div>
    </div>
  );
}

export function OpDialOutCall({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('update', () => hooks.run(ctrl));

  return (
    <div
      class="alert alert-success"
      use:hook={hooks.show('dialOutCall')}
    >
      {s.Op.dialOutCallCallerID} <span use:hook={hooks.text('dialOutCallCallerID')} />
      <br /><br />

      <div class="btn-toolbar">
        <button
          type="button"
          class="btn btn-primary"
          onclick={() => ctrl.dialOutCallConnectToConf()}
        >
          {s.Op.dialOutCallConnectToConf}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          onclick={() => ctrl.dialOutCallDrop()}
        >
          {s.Op.dialOutCallDrop}
        </button>
      </div>
    </div>
  );
}
