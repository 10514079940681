import errors from './errors';

import Loader from 'Loader';

import { ApiErrorNetwork, ApiErrorEnvelope, ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import ApiContextAuthRecording from 'Api/ApiContextAuthRecording';
import Location from 'Browser/Location';
import report from 'Error/report';
import { hide, show, updateContent } from 'Components/domHelpers';
import Alert from 'Components/Alert';
import MediaElementPlayer from 'Components/MediaElementPlayer';
import LoadingIndicator from 'Components/LoadingIndicator';
import { getTimezoneLabel } from 'DateTime';

import { PlaybackInstructions } from './LcmComponents';

import getMediaErrorCode from './getMediaErrorCode';
import getRecordingURLs from './getRecordingURLs';

import s from './strings';

function createErrorMessage(msg) {
  return (
    <div class="panel panel-danger mt-2">
      <div class="panel-heading">
        <h3 class="panel-title">{s.lblError}</h3>
      </div>
      <div class="panel-body">{msg}</div>
    </div>
  );
}

class RecordingPage {
  static isClassComponent = true;

  constructor({ authParams, siteConfig, ref }) {
    ref(this);

    this._authParams = authParams;
    this._siteConfig = siteConfig;

    this.refs = {};
    this.passcodeForm = {};

    this.root = (
      <div>
        <div class="recording-main-container" ref={this.view}>
          <h3>{s.lblConferenceRecording}</h3>
          <table>
            <tbody>
              <tr>
                <td class="label">{s.lblID}</td>
                <td class="_conferenceIDFormatted"></td>
              </tr>
              <tr ref={this.refs.bridgeNameContainer}>
                <td class="label">{s.lblName}</td>
                <td class="_bridgeName"></td>
              </tr>
              <tr>
                <td class="label">{s.lblStart}</td>
                <td class="_startedDate"></td>
              </tr>
              <tr>
                <td class="label">{s.lblEnd}</td>
                <td class="_endedDate"></td>
              </tr>
              <tr>
                <td></td>
                <td colspan="2" class="_timezone"></td>
              </tr>
            </tbody>
          </table>

          <div class="label">{s.lblRecording}</div>

          <div class="my-2" ref={this.refs.playerContainer}>
            <div class="media-element-player" ref={this.refs.player}></div>
          </div>

          <a href="#" target="_blank" class="downloadLink" ref={this.refs.download}>{s.lblDownload}</a>

          <PlaybackInstructions ref={this._instructions} />
        </div>

        <div ref={this.viewPasscode}>
          <Alert ref={this.passcodeForm.alert}/>
          <div class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-4 control-label" for="passcode">{s.lblRecordingPlaybackOptionsPasscode}</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="passcode" ref={this.passcodeForm.input} />
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-4"></div>
              <div class="col-sm-8">
                <button type="button" class="btn btn-primary" onclick={() => this._submitPasscode()}>{s.lblLogin}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    hide(this.view);

    this._playerLoadingIndicator = new LoadingIndicator(this.refs.player, {
      label: s.lblLoadingRecording,
    });

    hide(this.viewPasscode);

    this.passcodeForm.input.onkeypress = e => {
      if (e.which === 13) {
        this._submitPasscode();
      }
    };
  }

  load() {
    const { refs } = this;
    // setup api context
    const apiContext = new ApiContextAuthRecording('default', {
      ...this._siteConfig,
    });
    apiContext.setAuthParams({
      ...this._authParams
    });
    Api.addContext(apiContext);

    // send request

    const params = {
      checkOnlyFlag: true,
      dateFormat: 'shortDateTime12Hr',
    };

    Api.get('CDR', 'getRecording', params)
      .then(result => {
        const data = result.conferenceRecording;

        const {
          conferenceID,
          conferenceIDFormatted,
          bridgeName,
          startedDate,
          endedDate,
          recordingNumber,
          playBackNumberFormatted,
          timezone,
        } = data;

        updateContent(this.root, {
          conferenceID,
          conferenceIDFormatted,
          bridgeName,
          startedDate,
          endedDate,
          recordingNumber,
          playBackNumberFormatted,
          timezone: getTimezoneLabel(timezone),
        });

        if (!bridgeName) {
          hide(refs.bridgeNameContainer);
        }

        const { srcURL, downloadURL } = getRecordingURLs(data.url, 'CDR', 'getRecording', {});

        refs.download.href = downloadURL;

        this._instructions.render(data);

        show(this.view);

        return srcURL;
      })
      .then(srcURL => {
        const recordingPlayer = new MediaElementPlayer(refs.player);
        recordingPlayer.title = s.lblRecording;

        this._playerLoadingIndicator.show();

        recordingPlayer
          .on('loaded', () => {
            this._playerLoadingIndicator.hide();
          })
          .on('loaderror', e => {
            const { error } = e;
            const { playerContainer } = refs;

            const errorCode = getMediaErrorCode(error);
            playerContainer.after(createErrorMessage(errors[errorCode]));
            playerContainer.remove();
          })
          .load(srcURL);
      })
      .catch(err => {
        let errorCode = 'ERR_UNKNOWN';
        if (err instanceof ApiErrorEnvelope) {
          errorCode = 'ERR_INVALID_RECORDING_REQUEST';
        } else if (err instanceof ApiErrorResult) {
          const { code } = err;

          if ([ 'ERR_API_RECORDING_PASSCODE_REQUIRED', 'ERR_API_RECORDING_PASSCODE_INVALID' ].includes(code)) {
            this._renderPasscodeView(code);
            return;
          }
          if (code in errors) {
            errorCode = code;
          }
        } else if (!(err instanceof ApiErrorNetwork)) {
          setTimeout(() => {
            report.send('RecordingMain error', null, err);
          }, 0);
        }

        this.root.prepend(createErrorMessage(errors[errorCode]));
      });
  }

  _renderPasscodeView(code) {
    const { alert, input } = this.passcodeForm;

    alert.textContent = errors[code];

    if (!this._initialRequestSent && code === 'ERR_API_RECORDING_PASSCODE_REQUIRED') {
      hide(alert);
    } else {
      show(alert);
    }

    show(this.viewPasscode);
    setTimeout(() => input.focus(), 100);
  }

  _submitPasscode() {
    const { input } = this.passcodeForm;

    this._initialRequestSent = true;

    if (!input.value) {
      this._renderPasscodeView('ERR_API_RECORDING_PASSCODE_REQUIRED');
      return;
    }

    this._authParams.passcode = input.value;

    hide(this.viewPasscode);
    this.load();
  }
}

export default function main() {
  let siteConfig;
  let appContainer;

  Loader.load()
    .then(_siteConfig => {
      siteConfig = _siteConfig;
    })
    .then(() => {
      document.body.append(
        <div class="container-fluid">
          <div class="logo-container my-3" />
          <div ref={appContainer}>
          </div>
        </div>
      );
    })
    .then(() => Loader.setLogo())
    .then(() => {
      // parse request params
      const { accountID, cdrID, recID, s, passcode } = Location.query;

      if (!accountID || !cdrID || !s) {
        appContainer.appendChild(createErrorMessage(errors.ERR_INVALID_RECORDING_REQUEST));
        return;
      }

      const authParams = {
        accountID,
        cdrID,
        signature: s,
        recID,
        passcode,
      };

      let page;
      appContainer.append(
        <RecordingPage authParams={authParams} siteConfig={siteConfig} ref={page} />
      );
      page.load();
    })
    .then(() => Loader.loadComplete())
    .catch(err => Loader.loadError(err));
}
