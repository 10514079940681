import EventEmitter from 'events';

import Api from 'Api/Api';

import TaskScheduler from './TaskScheduler';

const OP_STATUS_CANCEL_ID = 'OpControllerStatus';

// the following are all in seconds
const POLL_DELAY = 10;

export default class OpController extends EventEmitter {
  constructor(config) {
    super();

    this._config = config;

    this._opUid = null;

    this._isConnected = false;
    this._isConfirmed = false;
    this._isConnectedToConf = false;
    this._isConnectedToConfOther = false;
    this._isConnectedToCall = false;

    this._dialOutCall = null;

    this._opState = 'disconnected';
    this._showConnectToConf = false;

    this._initPollerState();

    this._poller = new TaskScheduler({
      task: this._sendPollRequest.bind(this),
    });
  }

  start(loginData) {
    const { identity } = loginData;

    this._identity = identity;

    if (this.isOperator) {
      this._poller.scheduleNext();
    }

    this.emit('update');
  }

  stop() {
    this._poller.stop();
    this._initPollerState();

    this._identity = null;
  }

  connectToConf(listen = false) {
    const subConferenceID = null;

    const params = {
      subConferenceID,
      uid: this._opUid,
      muted: listen,
      host: !listen,
    };

    this._opDisconnect()
      .then(() => Api.get('Queue', 'operatorConnectToConference', params))
      .then(result => {
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }
      });
  }

  dialOutCallConnectToConf() {
    if (!this._dialOutCall) {
      return;
    }

    const {
      queueName,
      uid,
      lockID,
    } = this._dialOutCall;

    const params = {
      queueName,
      queueType: 'PreConf',
      uid,
      lockID,
    };

    Api.get('Queue', 'queueConnectToConference', params)
      .then(() => this._opDisconnect())
      .catch(err => {
      });
  }

  dialOutCallDrop() {
    if (!this._dialOutCall) {
      return;
    }

    const {
      queueName,
      uid,
      lockID,
    } = this._dialOutCall;

    const params = {
      queueName,
      queueType: 'PreConf',
      uid,
      lockID,
    };

    Api.get('Queue', 'queueDisconnect', params)
      .then(() => this._opDisconnect())
      .catch(err => {
      });
  }

  makeCallRequest(params) {
    const connectData = {};

    params.lock = 1;
    params.autoStart = 0;

    return Api.get('Queue', 'participantMakeCall', params)
      .then(({ makeCall: result }) => {
        connectData.queueName = result.data.ID;
        connectData.uid = result.data.uid;
        connectData.sipHandle = result.data.sipHandle;
        connectData.sipAddress = result.data.sipAddress;
        connectData.lockID = result.data.lockID;
        connectData.callerID = result.toNumber;
      })
      .then(() => this._opDisconnect())
      .then(() => Api.get('Queue', 'operatorConnect', {
        sipHandle: connectData.sipHandle,
        uid: this._opUid,
        lockID: connectData.lockID,
        connectData,
      }));
  }

  _opDisconnect() {
    const params = {
      uid: this._opUid,
    };

    return Api.get('Queue', 'operatorDisconnect', params);
  }

  _initPollerState() {
    this._curVersion = null;
  }

  _sendPollRequest() {
    // don't send requests when not logged in
    if (!this._identity) {
      return;
    }

    const params = {
      delay: POLL_DELAY,
      version: this._curVersion,
    };

    Api.get('Queue', 'operatorStatus', params, { cancelID: OP_STATUS_CANCEL_ID })
      .then(result => {
        const { operatorStatus } = result;
        this._curVersion = operatorStatus.version;
        this._onPollerUpdate(operatorStatus);

        this._poller.scheduleNext();
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }
      });
  }

  _onPollerUpdate(result = null) {
    this._opUid = null;

    this._isConnected = false;
    this._isConfirmed = false;
    this._isConnectedToConf = false;
    this._isConnectedToConfOther = false;
    this._isConnectedToCall = false;

    this._dialOutCall = null;

    let status;

    const keys = result.list && Object.keys(result.list);
    if (keys && keys.length) {
      status = result.list[keys[0]];
    }

    if (status) {
      this._opUid = status.uid;

      this._isConnected = true;

      const callState =
        status.data &&
        status.data.callStatus &&
        status.data.callStatus.status &&
        status.data.callStatus.status.state;

      this._isConfirmed = callState === 'confirmed';

      const sessionData =
        status.data &&
        status.data.sessionData || {};

      const connectionStatus =
        status.data &&
        status.data.connectionStatus;

      if (connectionStatus) {
        if (connectionStatus.status === 'connectToConf') {
          this._isConnectedToConf = connectionStatus.connectedToAuthConf;
          this._isConnectedToConfOther = !connectionStatus.connectedToAuthConf;
        } else if (connectionStatus.status === 'connectToSIP') {
          this._isConnectedToCall = true;

          const {
            queueName,
            uid,
            sipHandle,
            sipAddress,
            lockID,
            callerID,
          } = sessionData;

          if (
            queueName &&
            uid &&
            sipHandle &&
            sipAddress === connectionStatus.uri &&
            lockID &&
            callerID
          ) {
            this._dialOutCall = {
              queueName,
              uid,
              sipHandle,
              sipAddress,
              lockID,
              callerID,
            };
          }
        }
      }
    }

    if (this._dialOutCall) {
      this._opState = 'connectedToOutbound';
    } else if (this._isConnectedToCall) {
      this._opState = 'connectedToCallOther';
    } else if (this._isConnectedToConf) {
      this._opState = 'connectedToConf';
    } else if (this._isConnectedToConfOther) {
      this._opState = 'connectedToConfOther';
    } else if (this._isConnected) {
      this._opState = 'idle';
    } else {
      this._opState = 'disconnected';
    }

    this._showConnectToConf = [
      'connectedToCallOther',
      'connectedToConfOther',
      'idle',
    ].includes(this._opState);

    this.emit('update');
  }

  get isOperator() {
    return !!(this._identity && this._identity.operatorFlag);
  }

  get loginCode() {
    if (!this._identity)
      return null;

    return this._identity.operatorID;
  }

  get useOperatorMakeCall() {
    return !!(this._config.useOpMakeCall && this.isOperator);
  }

  get isConnected() {
    return this._isConnected;
  }

  get isConfirmed() {
    return this._isConfirmed;
  }

  get isConnectedToConf() {
    return this._isConnectedToConf;
  }

  get isConnectedToCall() {
    return this._isConnectedToCall;
  }

  get dialOutCallCallerID() {
    if (!this._dialOutCall) {
      return null;
    }

    return this._dialOutCall.callerID;
  }

  get dialOutCall() {
    return this._dialOutCall;
  }

  get opState() {
    return this._opState;
  }

  get showConnectToConf() {
    return this._showConnectToConf;
  }
}
