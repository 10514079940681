import { Popup } from 'Components/Popup';
import RangeInput from 'Components/RangeInput';

export function RangeInputPopup({ hooks, onChange, valueProp, children: anchor, min = 0, max = 1, step = 'any', placement = 'top', vertical = true }) {
  if (!(anchor instanceof HTMLElement))
    throw new Error('HTMLElement child required');

  let popup;
  let inputContainer;
  let input;

  hooks.add(valueProp, val => input.value = val);

  anchor.onfocus = () => popup.show();
  anchor.onmouseover = () => popup.show();

  const focusoutListener = e => {
    // workaround for safari.
    // in safari, prevent hiding popup when input is clicked and focus is lost.
    if (!e.relatedTarget) {
      return;
    }

    if (popup.root.contains(e.relatedTarget)) {
      return;
    }

    popup.hide();
  };

  let anchorTabindex;

  const onShow = () => {
    // save anchor's current tabindex and set it to -1 to make it
    // unfocusable if shift-tab is used to move focus backwards
    anchorTabindex = anchor.hasAttribute('tabindex')
      ? anchor.getAttribute('tabindex')
      : null;
    anchor.setAttribute('tabindex', '-1');

    anchor.classList.add('focus');

    document.body.addEventListener('focusout', focusoutListener);
  };

  const onHide = () => {
    // restore anchor's tabindex
    if (anchorTabindex !== null)
      anchor.setAttribute('tabindex', anchorTabindex);
    else
      anchor.removeAttribute('tabindex');

    anchor.classList.remove('focus');

    document.body.removeEventListener('focusout', focusoutListener);
  };

  const onBeforePosition = () => {
    if (!vertical) return;

    popup.root.style.width = `${inputContainer.offsetHeight}px`;
    popup.root.style.height = `${inputContainer.offsetWidth}px`;

    inputContainer.style.transform = `translate(${-(inputContainer.offsetHeight / 2)}px, ${popup.root.style.height}) rotate(-90deg)`;
  };

  return (
    <>
      {anchor}
      <Popup anchor={anchor} extraClass="range-input-popup" placement={placement} onShow={onShow} onHide={onHide} onBeforePosition={onBeforePosition} autoHide withBackground={false} ref={popup}>
        <div class="popup-bg" ref={inputContainer}>
          <RangeInput
            min={min}
            max={max}
            step={step}
            onChange={e => onChange(input.value)}
            ref={input}
          />
        </div>
      </Popup>
    </>
  );
}
