"use strict";
export const infoRecordingConfig_validate = validate10;
const schema11 = {
  "$id": "#/definitions/infoRecordingConfig",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "array",
  "items": {
    "type": "object",
    "properties": {
      "tag": {
        "type": "string",
        "minLength": 1
      },
      "label": {
        "type": "string",
        "minLength": 1
      }
    },
    "required": ["tag", "label"]
  },
  "minItems": 1
};
const func2 = require("ajv/dist/runtime/ucs2length").default;

function validate10(data, {
  instancePath = "",
  parentData,
  parentDataProperty,
  rootData = data
} = {}) {
  let vErrors = null;
  let errors = 0;
  if (errors === 0) {
    if (Array.isArray(data)) {
      if (data.length < 1) {
        validate10.errors = [{
          instancePath,
          schemaPath: "#/minItems",
          keyword: "minItems",
          params: {
            limit: 1
          },
          message: "must NOT have fewer than 1 items"
        }];
        return false;
      } else {
        var valid0 = true;
        const len0 = data.length;
        for (let i0 = 0; i0 < len0; i0++) {
          let data0 = data[i0];
          const _errs1 = errors;
          if (errors === _errs1) {
            if (data0 && typeof data0 == "object" && !Array.isArray(data0)) {
              let missing0;
              if (((data0.tag === undefined) && (missing0 = "tag")) || ((data0.label === undefined) && (missing0 = "label"))) {
                validate10.errors = [{
                  instancePath: instancePath + "/" + i0,
                  schemaPath: "#/items/required",
                  keyword: "required",
                  params: {
                    missingProperty: missing0
                  },
                  message: "must have required property '" + missing0 + "'"
                }];
                return false;
              } else {
                if (data0.tag !== undefined) {
                  let data1 = data0.tag;
                  const _errs3 = errors;
                  if (errors === _errs3) {
                    if (typeof data1 === "string") {
                      if (func2(data1) < 1) {
                        validate10.errors = [{
                          instancePath: instancePath + "/" + i0 + "/tag",
                          schemaPath: "#/items/properties/tag/minLength",
                          keyword: "minLength",
                          params: {
                            limit: 1
                          },
                          message: "must NOT have fewer than 1 characters"
                        }];
                        return false;
                      }
                    } else {
                      validate10.errors = [{
                        instancePath: instancePath + "/" + i0 + "/tag",
                        schemaPath: "#/items/properties/tag/type",
                        keyword: "type",
                        params: {
                          type: "string"
                        },
                        message: "must be string"
                      }];
                      return false;
                    }
                  }
                  var valid1 = _errs3 === errors;
                } else {
                  var valid1 = true;
                }
                if (valid1) {
                  if (data0.label !== undefined) {
                    let data2 = data0.label;
                    const _errs5 = errors;
                    if (errors === _errs5) {
                      if (typeof data2 === "string") {
                        if (func2(data2) < 1) {
                          validate10.errors = [{
                            instancePath: instancePath + "/" + i0 + "/label",
                            schemaPath: "#/items/properties/label/minLength",
                            keyword: "minLength",
                            params: {
                              limit: 1
                            },
                            message: "must NOT have fewer than 1 characters"
                          }];
                          return false;
                        }
                      } else {
                        validate10.errors = [{
                          instancePath: instancePath + "/" + i0 + "/label",
                          schemaPath: "#/items/properties/label/type",
                          keyword: "type",
                          params: {
                            type: "string"
                          },
                          message: "must be string"
                        }];
                        return false;
                      }
                    }
                    var valid1 = _errs5 === errors;
                  } else {
                    var valid1 = true;
                  }
                }
              }
            } else {
              validate10.errors = [{
                instancePath: instancePath + "/" + i0,
                schemaPath: "#/items/type",
                keyword: "type",
                params: {
                  type: "object"
                },
                message: "must be object"
              }];
              return false;
            }
          }
          var valid0 = _errs1 === errors;
          if (!valid0) {
            break;
          }
        }
      }
    } else {
      validate10.errors = [{
        instancePath,
        schemaPath: "#/type",
        keyword: "type",
        params: {
          type: "array"
        },
        message: "must be array"
      }];
      return false;
    }
  }
  validate10.errors = vErrors;
  return errors === 0;
}
