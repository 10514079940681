import { show, hide, toggleClass } from 'Components/domHelpers';
import Alert from 'Components/Alert';

import strings from './strings';
import errors from './errors';

export default class Subpage {
  constructor() {
    this._derivedParams = {};

    this._preActivateFuncs = [];
  }

  init(root, { enableSortingParams = false, defaultBackSubpage = null, } = {}) {
    this.root = root;
    document
      .querySelector('.app-main-subpages')
      .append(this.root);
    this.content = this.root.querySelector('.subpage-content');

    this._alert = (
      <Alert>
        <span ref={this._alertMessage}></span>
        <button type="button" class="btn btn-primary ml-3" ref={this._alertBackButton} onclick={() => this.back()}>{strings.lblBack}</button>
      </Alert>
    );

    this.root.prepend(this._alert);

    if (enableSortingParams) {
      this._metadata.params = {
        ...this._metadata.params,
        sortColumn: {
          defaultVal: this._metadata.sortColumnDefault,
          validateFunc: this.validateEnumStringFactory(this._metadata.sortColumns),
        },
        sortDirection: {
          defaultVal: 'asc',
          validateFunc: this.validateEnumStringFactory(['asc', 'desc']),
        },
      };
    }

    this.root
      .querySelectorAll('.btn-print')
      .forEach(_ => _.onclick = () => {
        this.redispatch({
          print: 1
        });
      });

    this.root
      .querySelectorAll('.btn-print-cancel')
      .forEach(_ => _.onclick = () => {
        this.redispatch({
          print: 0
        });
      });

    this.root
      .querySelectorAll('.btn-print-confirm')
      .forEach(_ => _.onclick = () => window.print());

    this._defaultBackSubpage = defaultBackSubpage;
  }

  dispatch() {
    hide(this._alert);
    show(this.content);

    this._preActivateFuncs.forEach(func => func());

    return this.activate();
  }

  detach() {
  }

  addPreActivateFunc(func) {
    this._preActivateFuncs.push(func);
  }

  show() {
    show(this.root);
  }

  displayError(errorCode, back = false) {
    this._alertMessage.textContent = errors[errorCode];
    if (back) {
      hide(this.content);
      show(this._alertBackButton);
    } else {
      hide(this._alertBackButton);
    }
    show(this._alert);
  }

  processParams(source, fromHash) {
    this._params = this._processFieldGroup(this._metadata.params, source, fromHash);
    this._options = this._processFieldGroup(this._metadata.options, source, fromHash);
  }

  _processFieldGroup(group, source, fromHash) {
    const ret = {};

    Object.keys(group).forEach(_ => {
      const def = this._getFieldDef(group, _);

      if (!(fromHash && !def.isHashParam)) {
        const { key } = def;
        if (key in source) {
          const val = def.validateFunc.call(this, source[key]);

          if (val !== undefined) {
            ret[_] = val;
            return;
          }
        }
      }

      ret[_] = def.defaultVal;
    });

    return ret;
  }

  _getFieldDef(group, fieldName) {
    const { defaultVal = null, validateFunc = this.validateString, isHashParam = true, key = fieldName } = group[fieldName];
    return {
      key,
      defaultVal,
      validateFunc,
      isHashParam,
    };
  }

  setFieldDefault(name, value) {
    this._metadata.params[name].defaultVal = value;
    if (this._params[name] === null)
      this._params[name] = value;
  }

  addDerivedParam(name, get) {
    Object.defineProperty(this._derivedParams, name, {
      get,
    });
  }

  validateString(val) {
    if (typeof val !== 'string') {
      return;
    }

    return val.trim();
  }

  validateUInt(val) {
    const type = typeof val;
    if (!(type === 'string' || type === 'number')) {
      return;
    }

    if (type === 'string') {
      val = parseInt(val, 10);
    }

    if (!Number.isInteger(val)) {
      return;
    }

    if (val < 0) {
      return;
    }

    return val;
  }

  validateBool(val) {
    val = this.validateUInt(val);
    if (val === undefined) {
      return;
    }

    if (!(val === 0 || val === 1)) {
      return;
    }

    return val;
  }


  validateEnumStringFactory(values) {
    return val => {
      val = this.validateString(val);
      if (val === undefined) {
        return;
      }

      if (!values.includes(val)) {
        return;
      }

      return val;
    };
  }

  validateEnumUIntFactory(values) {
    return val => {
      val = this.validateUInt(val);
      if (val === undefined) {
        return;
      }

      if (!values.includes(val)) {
        return;
      }

      return val;
    };
  }

  validateObject(val) {
    if (val && typeof val === 'object')
      return val;
    return;
  }

  getCanonicalParams() {
    return {
      ...this._getFieldGroupParams(this._metadata.params, this._params),
      ...this._getFieldGroupParams(this._metadata.options, this._options),
    };
  }

  _getFieldGroupParams(group, source) {
    const ret = {};

    Object.keys(source).forEach(_ => {
      const def = this._getFieldDef(group, _);
      if (!def.isHashParam)
        return;

      const val = source[_];
      if (val !== def.defaultVal) {
        ret[def.key] = val;
      }
    });

    return ret;
  }

  getStateValues(list) {
    const params = list.reduce((acc, name) => {
      acc[name] = this.getStateValue(name);
      return acc;
    }, {});

    if (this._metadata.transformApiParams) {
      this._metadata.transformApiParams(params);
    }

    return params;
  }

  getStateValue(name) {
    if (name in this._params)
      return this._params[name];
    if (name in this._options)
      return this._options[name];
    if (name in this._derivedParams)
      return this._derivedParams[name];

    throw new Error(`unknown field: ${name}`);
  }

  redispatch(override = {}) {
    this.subpageDispatcher.openSubpage(null, {
      ...this.getCanonicalParams(),
      ...override
    });
  }

  setPrintView(flag) {
    toggleClass(document.body, 'body-print', flag);
  }

  changeSort(sortColumn, sortDirection) {
    this.redispatch({
      page: 1,
      sortColumn,
      sortDirection,
    });
  }

  onSearch(search) {
    this.redispatch({
      search,
      page: 1,
    });
  }

  back() {
    if (this._options.back) {
      window.location.hash = `#${this._options.back}`;
    } else if (this._defaultBackSubpage) {
      this.subpageDispatcher.openSubpage(this._defaultBackSubpage);
    } else {
      this.subpageDispatcher.openDefaultSubpage();
    }
  }

  get requestGroup() {
    return this._metadata.object.requestGroup;
  }

  get getMethod() {
    return this._metadata.object.getMethod;
  }

  get sortProps() {
    const { sortColumn, sortDirection } = this._params;

    return [
      {
        colId: sortColumn,
        order: sortDirection,
      },
    ];
  }

  openSubpage(...args) {
    this.subpageDispatcher.openSubpage(...args);
  }

  getCanonicalHash() {
    return this.subpageDispatcher.getCanonicalHash();
  }

  get subpageDispatcher() {
    return this._portal.subpageDispatcher;
  }

  get ctrl() {
    return this._portal.ctrl;
  }

  get opCtrl() {
    return this._portal.opController;
  }

  get isActive() {
    return this.subpageDispatcher.currentSubpage === this;
  }

  getActiveTabKey() {
    return this.tabKey;
  }
}
