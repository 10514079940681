import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import Alert from 'Components/Alert';
import { toggle, show, hide } from 'Components/domHelpers';
import { RefFormData, FormGroup, FormPassthru, FormText, FormTextarea, FormCheckbox, FormStatic, wrapSubmitHandler } from 'Components/FormComponents';
import { hook } from 'Components/Hooks';
import HookedList from 'Components/HookedList';
import LoadingIndicator from 'Components/LoadingIndicator';
import MediaElementController from 'Components/MediaElementController';
import Modal from 'Components/Modal';
import TbIcons from 'Components/TbIcons';

import {
  IR_STATE_PENDING,
  IR_STATE_RUNNING,
  IR_STATE_RECORDED,
  IR_STATE_SKIPPED,
} from './ConfConstants';
import getRecordingURLs from './getRecordingURLs';
import isPSTN from './isPSTN';
import { PlayStopButton } from './LcmComponents';
import NotesObjectForm from './NotesObjectForm';
import appErrorHandler from './appErrorHandler';
import ModalAlert from './ModalAlert';

import s from './strings';
import errors from './errors';

const ADDRESS_BOOK_BASE_FIELDS = [
  'ext',
  'blockedFlag',
  'hostFlag',
  'holdFlag',
  'dialExtension',
];

const ADDRESS_BOOK_VISIBLE_FIELDS = [
  ...ADDRESS_BOOK_BASE_FIELDS,
  'save',
];

const ADDRESS_BOOK_COPY_FIELDS = [
  'entryID',
  ...ADDRESS_BOOK_BASE_FIELDS,
];

const ADDRESS_BOOK_SAVE_FIELDS = [
  'name',
  ...ADDRESS_BOOK_BASE_FIELDS,
];

export class CallerInfoModal {
  constructor({ ctrl, footer, onSubmit, onClose = null, getPlaybackURLs = null }) {
    const onCloseHandler = onClose || (() => this.hide());

    const getPlaybackURLsDefault = tag => {
      const callID = this.form.get('callID');
      return getRecordingURLs('', 'LCM', 'getCallNameRecording', { callID, tag });
    };

    this._ctrl = ctrl;

    this._modal = new Modal({
      appendToBody: true,
      title: s.lblDialogTitleCallerInfo,
      children: (
        <form onsubmit={wrapSubmitHandler(onSubmit)}>
          <div class="modal-body">
            <div class="form-horizontal">
              <Alert ref={this._alert} />
              <CallerInfoForm
                ctrl={ctrl}
                getPlaybackURLs={getPlaybackURLs || getPlaybackURLsDefault}
                ref={this.form}
              />
            </div>
          </div>
          <div class="modal-footer">
            {footer}
          </div>
        </form>
      ),
      onClose: onCloseHandler,
    });

    this.loading = new LoadingIndicator(this._modal.bodyAndFooter, {
      label: s.lblLoading,
    });
  }

  showStart() {
    hide(this._alert);
    this.loading.show();
    this._modal.show();
  }

  showFinish(call, showAddressBookFields) {
    this.form.render(call, { showAddressBookFields });
    this.loading.hide();
    this._modal.focusFirstDescendant();

    if (call.participantID) {
      this._observer = this._ctrl.createCallObserver(call.participantID, {
        onUpdate: call => {
          this.form.recordingsList.render(call.recordings);
        },
        onSubConfChange: () => {
          this.hide();
          ModalAlert.display('ERR_CALL_SUB_CONF_CHANGED');
        },
        onGone: () => {
          this.hide();
          ModalAlert.display('ERR_CALL_DISCONNECTED');
        },
      });
    }
  }

  hide() {
    this._modal.hide();

    if (this._observer) {
      this._observer.destroy();
      this._observer = null;
    }

    MediaElementController
      .stop()
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        appErrorHandler(err);
      });
  }

  displayError(errorCode) {
    this._alert.textContent = errors[errorCode];
    show(this._alert);
  }
}

export class CallerInfoAddressBookModal {
  constructor({ ctrl }) {
    this._ctrl = ctrl;
    this._modal = new CallerInfoModal({
      ctrl,
      footer: (
        <>
          <button type="submit" class="btn btn-primary">{s.lblSave}</button>
          <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
        </>
      ),
      onSubmit: () => this.save(),
    });
  }

  display(call) {
    this._modal.showStart();

    let showAddressBookFields = isPSTN(call);

    Promise.resolve()
      .then(() => {
        if (showAddressBookFields) {
          return this._lookup(call)
            .catch(err => {
              if (err instanceof ApiErrorResult) {
                if (err.isInvalidParamError() && err.parameterName === 'phoneNumber') {
                  // render without showAddressBookFields
                  showAddressBookFields = false;
                  return call;
                }
              }

              throw err;
            });
        }

        return call;
      })
      .then(call => {
        this._modal.showFinish(call, showAddressBookFields);
      })
      .catch(err => {
        this._modal.hide();
        ModalAlert.display(appErrorHandler(err));
      });
  }

  _lookup(call) {
    const params = {
      phoneNumber: call.number,
      resultCount: 1,
    };

    return Api.get('Bridge', 'getAddressBook', params)
      .then(res => res.addressBookEntry && res.addressBookEntry[0])
      .then(entry => {
        const entryCopy = {};
        if (entry) {
          ADDRESS_BOOK_COPY_FIELDS.forEach(_ => entryCopy[_] = entry[_]);
        } else {
          entryCopy.phoneNumber = call.number;
        }

        return {
          ...call,
          ...entryCopy,
          save: !this._ctrl.operatorFlag,
        };
      });
  }

  save() {
    const data = this._modal.form.getData();

    this._modal.loading.show();

    Promise.resolve()
      .then(() => {
        if (data.showAddressBookFields && data.save) {
          return this._saveEntry(data);
        }
      })
      .then(() => this._ctrl.saveCallerInfo(data.callID, data))
      .then(() => {
        this._modal.hide();
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        const errorCode = appErrorHandler(err);
        this._modal.displayError(errorCode);
      })
      .then(() => this._modal.loading.hide());
  }

  _saveEntry(data) {
    const { entryID, notesType } = data;
    const params = {
      ...(entryID
        ? {
          editMode: 'modifyOnly',
          entryID,
        }
        : {
          editMode: 'createOnly',
          phoneNumber: data.phoneNumber,
        }
      ),
      ...(notesType === 'string'
        ? {
          notes: data.notes,
        }
        : {
          callDataPerm: data.notesObject,
        }
      ),
    };

    ADDRESS_BOOK_SAVE_FIELDS.forEach(_ => params[_] = data[_]);

    return Api.get('Bridge', 'setAddressBookEntry', params);
  }
}

export class CallerInfoForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctrl, getPlaybackURLs, ref }) {
    super();

    if (ref) {
      ref(this);
    }

    const IR_STATE_MAP = new Map([
      [ IR_STATE_PENDING,  s.IR_STATE_MAP.pending ],
      [ IR_STATE_RUNNING,  s.IR_STATE_MAP.running ],
      [ IR_STATE_RECORDED, s.IR_STATE_MAP.recorded ],
      [ IR_STATE_SKIPPED,  s.IR_STATE_MAP.skipped ],
    ]);

    this.root = (
      <>
        <FormPassthru form={this} name="callID" />
        <FormPassthru form={this} name="notesType" />

        <FormPassthru form={this} name="showAddressBookFields" />
        <FormPassthru form={this} name="entryID" />
        <FormPassthru form={this} name="phoneNumber" />

        <FormStatic form={this} name="toNumberFormatted" label={s.lblAccessNumber} />
        <FormStatic form={this} name="callerIDDisplay" label={s.lblCallerID} />
        <FormText form={this} name="ext" label={s.lblExtension} />
        <HookedList
          ref={this.recordingsList}
          itemKey="tag"
          itemElementType="div"
          createItemNode={(tag, hooks, hooksExtra) => {
            return (
              <FormGroup label={<span use:hook={hooks.text('label')} />}>
                <div use:hook={hooks.showEq('state', IR_STATE_RECORDED)}>
                  <PlayStopButton
                    uri={() => getPlaybackURLs(tag).srcURL}
                    playIcon={TbIcons.PLAY_CIRCLE}
                    stopIcon={TbIcons.STOP_CIRCLE}
                  />
                </div>
                <p
                  class="form-control-static"
                  use:hook={hooks.hideEq('state', IR_STATE_RECORDED)}
                  use:hook={hooks.text('state', state => IR_STATE_MAP.get(state))}
                />
              </FormGroup>
            );
          }}
        >
          <div />
        </HookedList>
        <FormText form={this} name="name" label={s.lblName} inputAttributes={{ autocomplete: 'off' }} />
        <FormCheckbox form={this} name="blockedFlag" label={s.lblBlock} labelRight={s.lblUserBlockNote} />
        <FormCheckbox form={this} name="hostFlag" label={s.lblJoinAsHost} labelRight={s.lblUserHostNote} />
        <FormCheckbox form={this} name="holdFlag" label={s.lblJoinOnHold} />
        <FormCheckbox form={this} name="dialExtension" label={s.lblDialExtension} />
        <div ref={this._notesObjectContainer}>
          <NotesObjectForm ctrl={ctrl} ref={this._notesObjectForm} />
        </div>
        <FormTextarea form={this} name="notes" label={s.lblNotes} />
        <FormCheckbox form={this} name="save" label={s.lblAddToCallerList} />
      </>
    );
  }

  render(data, { showAddressBookFields = false } = {}) {
    this.setVisibility('toNumberFormatted', isPSTN(data));

    const { notesType } = data;
    this.setVisibility('notes', notesType === 'string');
    toggle(this._notesObjectContainer, notesType === 'object');

    ADDRESS_BOOK_VISIBLE_FIELDS.forEach(field => this.setVisibility(field, showAddressBookFields));

    this.getAllFields().forEach(field => {
      this.set(field.name, data[field.name] || '');
    });

    this._notesObjectForm.render(data.notesObject);
    this.recordingsList.render(data.recordings);

    this.set('showAddressBookFields', showAddressBookFields);
  }

  getData() {
    return {
      ...this.getAllValues(),
      notesObject: this._notesObjectForm.getData(),
    };
  }
}
