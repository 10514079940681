import $ from 'jquery';

var open = false;

var ContextMenu = {
  widget : null,

  isOpen : function() {
    return open;
  },

  close : function() {
    $('body').find('.context-menu.open .dropdown-toggle').dropdown('toggle');
  }
};

export default ContextMenu;

// event handler moves "active" class to currently hovered on menu item. this applies to all bootstrap dropdowns
$(document).on('mouseover', '.dropdown-menu a', function(e) {
  var $ul = $(this).parents('ul');
  var $li = $(this).parents('li');

  if ($li.hasClass('disabled')) {
    return;
  }

  $ul.find('.active').removeClass('active');
  $li.addClass('active');
});

// context menu specific events

$(document).on('show.bs.dropdown', '.context-menu', function(e) {
  const $menu = $(this).find('.dropdown-menu');

  $(this).trigger(e = $.Event('show.tb.contextMenu'));

  $menu.empty();

  const menuItems = e.result;

  menuItems.forEach(item => {
    const $li = $('<li>'),
        $a = $('<a>');

    if (item.label)
      $a.text(item.label);

    if (item.value)
      $a.attr('href', item.value);

    if (item.disabled)
      $li.addClass('disabled', true);

    if (item.onclick) {
      $a[0].onclick = item.onclick;
      $a[0].setAttribute('href', '#');
    }

    $menu.append($li.append($a));
  });

  var bHeight = $(this).outerHeight();
  var bOffset = $(this).offset();
  var computedStyle = window.getComputedStyle(this);
  var isPositionedRelatively = computedStyle.position === 'relative';

  if (!isPositionedRelatively) {
    // position dropdown-menu
    var bPosition = $(this).position();
    var marginLeft = parseInt(computedStyle.marginLeft, 10);
    $menu[0].style.top = `${bPosition.top + bHeight}px`;
    $menu[0].style.left = `${bPosition.left + marginLeft}px`;
  }

  // add mouseleave target div
  $('body').append(
    $('<div>')
      .attr('id', 'context-target')
      .css({
        //"z-index": "6666",
        //"background-color" : "red",
        position : 'absolute',
        top      : bOffset.top,
        left     : bOffset.left,
        height   : bHeight + $menu.outerHeight(),
        width    : Math.max($(this).outerWidth(), $menu.outerWidth())
      })
  );

  open = true;
});

$(document).on('hidden.bs.dropdown', '.context-menu', function(e) {
  open = false;

  $('#context-target').remove();
  $(this).trigger(e = $.Event('hidden.tb.contextMenu'));
});

$(document).on('mouseleave', '#context-target, .context-menu', function(e) {
  if (!ContextMenu.isOpen())
    return;

  var div    = $('#context-target');
  var offset = div.offset();

  if (e.pageX >= offset.left && e.pageX <= (offset.left + div.width()) &&
      e.pageY >= offset.top && e.pageY <= (offset.top + div.height())) {

    return;
  }

  ContextMenu.close();
});

$(document).on('click', '.context-menu a', function(e) {
  e.preventDefault();

  if (this.onclick) {
    return;
  }

  $(this).trigger(e = $.Event('select.tb.contextMenu'));
});
