import EventEmitter from 'events';
import { nanoid } from 'nanoid/non-secure';

import ClientStorage from 'Browser/ClientStorage';

import { NotFoundError } from 'CommonExceptions';
import Api from 'Api/Api';
import { formatTimestamp } from 'DateTime';

import { getRecordingInfo } from './ApiGetters';
import { PagingController } from './PagingController';

export { GAIN_MIN, GAIN_MAX } from './ConfConstants';

const BROADCAST_QUEUE_KEY = 'lcm_broadcastQueue';
const SELECT_PROP = 'isItemSelected';

const BROADCAST_ID_LEN = 16;

export const ADD_MODAL_STATE_CLOSED = 0;
export const ADD_MODAL_STATE_CHOOSE_ACTION = 1;

export const ADD_MODAL_STATE_UPLOAD = 2;
export const ADD_MODAL_STATE_UPLOADING = 3;

export const ADD_MODAL_STATE_LOADING = 4;
export const ADD_MODAL_STATE_SELECT = 5;

export const ADD_MODAL_STATE_ADD_TO_PLAYLIST = 6;

const ADD_MODAL_CANCEL_ID = 'BroadcastControllerUploadRecording';

const SELECT_MODAL_RESULT_COUNT = 5;

export default class BroadcastController extends EventEmitter {
  constructor() {
    super();

    this._broadcastQueue = [];
    this._activeBroadcast = null;
    this._panelVisible = false;
    this._queueVisible = true;

    this._addModalState = ADD_MODAL_STATE_CLOSED;
    this._addModalRecording = {};

    this.pagingController = new PagingController({
      onPageChange: page => this._selectModalPageChange(page),
      resultCountDefault: SELECT_MODAL_RESULT_COUNT,
      resultCountOpts: null,
    });

    this._selectModalItems = [];
    this._selectModalInitParams();

    this._writable = true;

    setInterval(() => this._tick(), 1000);
  }

  _tick() {
    if (this._activeBroadcast && this._activeBroadcast.isPlaying) {
      this._activeBroadcast.position++;
      this.emit('update');
    }
  }

  _sendCallCommand(command, callID, value, params) {
    const reqParams = {
      command,
      callID,
      value,
    };

    if (params)
      reqParams.params = params;

    return Api.get('LCM', 'changeConferenceCall', reqParams);
  }

  _spliceBroadcastQueue(fromIndex, toIndex) {
    const item = this._broadcastQueue.splice(fromIndex, 1)[0];
    this._broadcastQueue.splice(toIndex, 0, item);
  }

  _writeStoredQueue() {
    ClientStorage.writeJSON(BROADCAST_QUEUE_KEY, this._broadcastQueue);
  }

  _getItemIndexByBroadcastID(broadcastID) {
    const index = this._broadcastQueue.findIndex(item => item.broadcastID === broadcastID);
    if (index === -1)
      throw new NotFoundError(`Queue item with broadcastID ${broadcastID} not found`);

    return index;
  }

  start() {
    const storedQueue = ClientStorage.readJSON(BROADCAST_QUEUE_KEY);
    if (Array.isArray(storedQueue))
      storedQueue.forEach(item => {
        if (!(item && typeof item === 'object'))
          return;

        this._addToQueue(item);
      });

    this.emit('update');
  }

  stop() {
    this._broadcastQueue = [];
    ClientStorage.delete(BROADCAST_QUEUE_KEY);
  }

  addToQueue({ cdrID, recID, customID, duration }) {
    this._queueVisible = true;
    this._addToQueue({
      broadcastID: nanoid(BROADCAST_ID_LEN),
      cdrID,
      recID,
      customID,
      duration,
    });
    this._writeStoredQueue();
    this.emit('update');
  }

  _addToQueue({ broadcastID, cdrID, recID, customID, duration, inGain = 0 }) {
    this._broadcastQueue.push({
      broadcastID,
      cdrID,
      recID,
      customID,
      duration,
      inGain,
      isItemSelected: 0,
      isInProgress: false,
      isPlaying: false,
    });
  }

  removeFromQueue(broadcastID) {
    this._removeFromQueue(broadcastID);

    this._writeStoredQueue();
    this.emit('update');
  }

  _removeFromQueue(broadcastID) {
    const index = this._getItemIndexByBroadcastID(broadcastID);
    this._broadcastQueue.splice(index, 1);
  }

  removeSelected() {
    const selected = this._getSelectedItems();
    selected.forEach(item => {
      this._removeFromQueue(item.broadcastID);
    });

    this._writeStoredQueue();
    this.emit('update');
  }

  removeAll() {
    this._broadcastQueue = [];
    this._writeStoredQueue();
    this.emit('update');
  }

  move(direction) {
    const selected = this._getSelectedItems();

    if (selected.length === 1) {
      this.itemMove(selected[0].broadcastID, direction);
    } else if (selected.length > 1) {
      this._itemMoveMultiple(selected, direction);
    }
  }

  itemMove(broadcastID, direction, targetBroadcastID) {
    const index = this._getItemIndexByBroadcastID(broadcastID);
    const targetIndex = targetBroadcastID ? this._getItemIndexByBroadcastID(targetBroadcastID) : null;

    let toIndex;

    switch (direction) {
    case 'before':
    case 'after':
      toIndex = targetIndex;
      break;

    case 'up':
      if (index > 0) {
        toIndex = index - 1;
      }
      break;

    case 'down':
      if (index < this._broadcastQueue.length - 1) {
        toIndex = index + 1;
      }
      break;

    case 'top':
      toIndex = 0;
      break;

    case 'bottom':
      toIndex = this._broadcastQueue.length;
      break;

    default:
      throw new Error('invalid direction');
    }

    if (toIndex !== undefined) {
      this._spliceBroadcastQueue(index, toIndex);
      this._writeStoredQueue();
      this.emit('update');
    }
  }

  _itemMoveMultiple(items, direction) {
    switch (direction) {
    case 'top':
      items.reverse();
      break;
    case 'bottom':
      break;
    default:
      throw new Error('invalid direction');
    }

    items.forEach(item => {
      this.itemMove(item.broadcastID, direction);
    });
  }

  startBroadcast(broadcastID) {
    const item = this._getItemOrFail(broadcastID);

    const { cdrID, recID, duration, inGain, customID: description } = item;

    this.update({
      writable: this._writable,
      activeBroadcast: {
        isPlaceholder: true,
        broadcastID,
        callID: null,
        description,
        duration,
        inGain,
        position: 0,
        isPlaying: false,
      },
    });

    this.emit('update');

    const params = {
      broadcastID,
      cdrID,
      recID,
      description,
      inGain,
    };

    return Api.get('LCM', 'startBroadcast', params)
      .then(res => res.processStreamCall)
      .then(call => {
        if (!call)
          throw new NotFoundError();

        this.emit('update');
        this.emit('pollRequested');
      });
  }

  streamPauseToggle() {
    return Promise.resolve()
      .then(() => {
        if (!this._activeBroadcast)
          throw new NotFoundError();

        return this._activeBroadcast.callID;
      })
      .then(callID => this._sendCallCommand('runCallCmd', callID, 1, {
        command: 'streamCommand',
        params: {
          cmdName: 'pauseToggle',
        },
      }));
  }

  disconnectCall() {
    return Promise.resolve()
      .then(() => {
        if (!this._activeBroadcast)
          throw new NotFoundError();

        return this._activeBroadcast.callID;
      })
      .then(callID => this._sendCallCommand('disconnect', callID, 1));
  }

  toggleItemProperty(broadcastID, property) {
    const item = this._getItemOrFail(broadcastID);
    const value = item[property] ? 0 : 1;

    item[property] = value;
    this.emit('update');
  }

  togglePanelVisible() {
    this._panelVisible = !this._panelVisible;
    this.emit('update');
  }

  toggleQueueVisible() {
    this._queueVisible = !this._queueVisible;
    if (this._queueVisible)  {
      this.emit('update');
    } else {
      // _select() internally triggers re-render
      this._select('none');
    }
  }

  toggleSelectAll() {
    const { allSelected } = this._getSelectState();
    this._select(allSelected ? 'none' : 'all');
  }

  _getItem(broadcastID) {
    return this._broadcastQueue.find(item => item.broadcastID === broadcastID);
  }

  _getItemOrFail(broadcastID) {
    const item = this._getItem(broadcastID);
    if (!item)
      throw new Error(`No broadcast item found for broadcastID ${broadcastID}`);

    return item;
  }

  _getSelectedItems() {
    return this._broadcastQueue.filter(item => item[SELECT_PROP]);
  }

  _getSelectState(filterFunc = null) {
    let empty = true;
    let allSelected = true;
    let someSelected = false;
    let count = 0;

    this._broadcastQueue.forEach(item => {
      if (filterFunc && !filterFunc(item))
        return;

      empty = false;

      if (!item[SELECT_PROP]) {
        allSelected = false;
      } else {
        someSelected = true;
        count++;
      }
    });

    allSelected = allSelected && !empty;

    return {
      allSelected,
      someSelected,
      count,
    };
  }

  _select(type, filterFunc = null) {
    this._broadcastQueue.forEach(item => {
      if (filterFunc && !filterFunc(item))
        return;

      let selected = false;

      switch (type) {
      case 'all':
        selected = true;
        break;
      }

      item[SELECT_PROP] = selected ? 1 : 0;
    });

    this.emit('update');
  }

  update({ writable, activeBroadcast }) {
    this._writable = writable;

    if (activeBroadcast && !activeBroadcast.isPlaceholder) {
      const old = this._activeBroadcast;
      if (old && old.broadcastID === activeBroadcast.broadcastID && old.position > activeBroadcast.position)
        activeBroadcast.position = old.position;

      const item = this._getItem(activeBroadcast.broadcastID);
      if (item && item.inGain !== activeBroadcast.inGain) {
        item.inGain = activeBroadcast.inGain;
        this._writeStoredQueue();
      }
    }

    this._activeBroadcast = activeBroadcast;

    this._broadcastQueue.forEach(item => {
      item.isInProgress = false;
      item.isPlaying = false;

      if (activeBroadcast && activeBroadcast.broadcastID === item.broadcastID) {
        item.isInProgress = true;
        item.isPlaying = activeBroadcast.isPlaying;
      }
    });

    this.emit('update');
  }

  setInGain(inGain) {
    const callID = this._activeBroadcast.callID;
    this._sendCallCommand('setInGain', callID, inGain);
  }

  get broadcastQueue() {
    return this._broadcastQueue;
  }

  get activeBroadcast() {
    if (!this._activeBroadcast) {
      return {
        broadcastID: null,
        callID: null,
        description: null,
        duration: null,
        position: null,
        isPlaying: false,
        inGain: 0,
      };
    }

    return this._activeBroadcast;
  }

  get panelVisible() {
    return !!(this._activeBroadcast || this._broadcastQueue.length || this._panelVisible);
  }

  get broadcastQueueCount() {
    return this._broadcastQueue.length;
  }

  get selectedCount() {
    return this._getSelectState().count;
  }

  get selectState() {
    const { allSelected, someSelected } = this._getSelectState();
    if (allSelected)
      return 'all';

    if (someSelected)
      return 'some';

    return 'none';
  }

  get queueVisible() {
    return this._queueVisible;
  }

  get isExpandable() {
    return this._broadcastQueue.length > 0;
  }

  get writable() {
    return this._writable;
  }

  addModalOpen() {
    this._addModalState = ADD_MODAL_STATE_CHOOSE_ACTION;
    this.emit('update');
  }

  addModalDismiss() {
    if (this._addModalState === ADD_MODAL_STATE_UPLOADING) {
      Api.defaultContext.abort(ADD_MODAL_CANCEL_ID);
    }

    this._addModalState = ADD_MODAL_STATE_CLOSED;
    this.emit('update');
  }

  addModalChooseUpload() {
    this._addModalStateAction = ADD_MODAL_STATE_UPLOAD;
    this._addModalState = ADD_MODAL_STATE_UPLOAD;
    this.emit('update');
  }

  addModalChooseExisting() {
    this._addModalStateAction = ADD_MODAL_STATE_SELECT;
    this._selectModalInitParams();
    this._selectModalLoad();
  }

  addModalBack() {
    switch (this._addModalState) {
    case ADD_MODAL_STATE_UPLOAD:
    case ADD_MODAL_STATE_SELECT:
    case ADD_MODAL_STATE_LOADING:
      this._addModalState = ADD_MODAL_STATE_CHOOSE_ACTION;
      break;

    case ADD_MODAL_STATE_ADD_TO_PLAYLIST:
      this._addModalState = this._addModalStateAction;
      break;
    }
    this.emit('update');
  }

  uploadModalUpload(file, description, onUploadProgress) {
    this._addModalState = ADD_MODAL_STATE_UPLOADING;
    this._addModalRecording = {};
    this.emit('update');

    const params = {
      customID: description,
    };

    const opts = {
      cancelID: ADD_MODAL_CANCEL_ID,
      timeout: 0,
      files: {
        recording: file,
      },
      onUploadProgress: e => onUploadProgress(e),
    };

    return Api.get('CDR', 'uploadRecording', params, opts)
      .then(result => {
        const {
          cdrID,
          recID,
          customID,
          duration,
          startedDate
        } = result.conferenceRecording;

        this._addModalRecording = {
          cdrID,
          recID,
          customID,
          duration,
          durationDisplay: formatTimestamp(duration * 1000),
          startedDate,
        };

        this._addModalState = ADD_MODAL_STATE_ADD_TO_PLAYLIST;

        this.emit('update');
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        throw err;
      });
  }

  addModalAddToQueue(recording) {
    this.addToQueue(recording);
    this.addModalDismiss();
  }

  get addModalRecording() {
    return this._addModalRecording;
  }

  get addModalState() {
    return this._addModalState;
  }

  selectModalSelect(itemIdx) {
    if (!this._selectModalItems[itemIdx]) {
      throw new Error('invalid itemIdx');
    }

    this._addModalRecording = this._selectModalItems[itemIdx];
    this._addModalState = ADD_MODAL_STATE_ADD_TO_PLAYLIST;
    this.emit('update');
  }

  _selectModalPageChange(page) {
    this.pagingController.update({
      resultCount: this.pagingController.resultCount,
      page,
    });
    this._selectModalLoad();
  }

  selectModalSort(sortColumn, sortDirection) {
    this.pagingController.update({
      resultCount: this.pagingController.resultCount,
      page: 1,
    });

    this._selectModalSortColumn = sortColumn;
    this._selectModalSortDirection = sortDirection;
    this._selectModalLoad();
  }

  selectModalSubmitSearch(searchString) {
    this.pagingController.update({
      resultCount: this.pagingController.resultCount,
      page: 1,
    });

    this._selectModalSearchString = searchString;
    this._selectModalLoad();
  }

  _selectModalInitParams() {
    this.pagingController.update({
      resultCount: this.pagingController.resultCount,
      page: 1,
    });

    this._selectModalSortColumn = 'startedDate';
    this._selectModalSortDirection = 'desc';

    this._selectModalSearchString = '';
  }

  _selectModalLoad() {
    this._addModalState = ADD_MODAL_STATE_LOADING;
    this.emit('update');

    const params = {
      timezone: 'EST',
      recordingType: 'upload',

      resultCount: this.pagingController.resultCount,
      startOffset: this.pagingController.startOffset,

      sortColumn: this._selectModalSortColumn,
      sortDirection: this._selectModalSortDirection,

      search: this._selectModalSearchString,
    };

    getRecordingInfo(params)
      .then(recordingInfo => {
        this.pagingController.update({
          resultCount: this.pagingController.resultCount,
          page: this.pagingController.page,
          totalResults: recordingInfo.totalResults,
        });
        this._selectModalItems = recordingInfo.items;
        this._addModalState = ADD_MODAL_STATE_SELECT;
        this.emit('update');
      });
  }

  get selectModalItems() {
    return this._selectModalItems;
  }

  get selectModalSortColumn() {
    return this._selectModalSortColumn;
  }

  get selectModalSortDirection() {
    return this._selectModalSortDirection;
  }

  get selectModalSortProps() {
    return [
      {
        colId: this._selectModalSortColumn,
        order: this._selectModalSortDirection,
      },
    ];
  }

  get selectModalForm() {
    return {
      search: this._selectModalSearchString,
    };
  }
}
