import { hook, Hooks } from 'Components/Hooks';
import logger from 'Log/logger';

import strings from './strings';

const LCM_MODULE_NAME = 'lcm';

const GUARD_TAB_KEY = 'lcmGuardTab';

function getTabKey(subConfID) {
  let key = LCM_MODULE_NAME;
  if (subConfID) {
    key += `/${subConfID}`;
  }
  return key;
}

function TabLabel({ hooks, subConfID = null }) {
  const label = subConfID === null
    ? strings.lblLiveConference
    :`${strings.lblSubConferenceTab} ${subConfID}`;

  return (
    <span>
      {label}
      {' '}
      <span
        class="tb-tabs-notice"
        use:hook={hooks.toggleClass('noticeUrgent', 'tb-tabs-notice-urgent')}
        use:hook={hooks.show('noticeShow')}
        use:hook={hooks.text('noticeText')}
      />
    </span>
  );
}

const getLabelState = ({ callCount = 0, handRaisedCount = 0 }) => ({
  noticeShow: !!(callCount || handRaisedCount),
  noticeUrgent: !!handRaisedCount,
  noticeText: `${handRaisedCount ? `${handRaisedCount}/` : ''}${callCount}`,
});

export default class SubConfManager {
  constructor(subpageDispatcher, tabs, ctrl) {
    this.log = logger('Lcm:SubConfManager');

    this._subpageDispatcher = subpageDispatcher;
    this._tabs = tabs;
    this._ctrl = ctrl;
    this._subConfHooks = new Map();

    this._tabs.addTab({
      key: GUARD_TAB_KEY,
      cssClass: 'd-none',
    }, null, true);

    this._mainConfHooks = this._addTab(null, ctrl.mainConfCounts);

    this._ctrl.on('update', () => this._update());
  }

  _addTab(subConfID, counts) {
    const hooks = new Hooks();

    const key = getTabKey(subConfID);
    const subpageParams = {};
    if (subConfID) {
      subpageParams.id = subConfID;
    }

    this._tabs.addTab({
      key,
      href: `#${key}`,
      label: (
        <TabLabel hooks={hooks} subConfID={subConfID} />
      ),
      onclick: () => this._subpageDispatcher.openSubpage(LCM_MODULE_NAME, subpageParams),
    }, GUARD_TAB_KEY, true);

    hooks.run(getLabelState(counts));

    return hooks;
  }

  _update() {
    const { subConfs } = this._ctrl;

    // update labels, remove inactive tabs
    this._subConfHooks.forEach((hooks, subConfID) => {
      const subConf = subConfs.find(cur => cur.name === subConfID);
      if (!subConf) {
        this.log(`removeSubConf(${subConfID})`);

        this._tabs.removeTab(getTabKey(subConfID));
        this._subConfHooks.delete(subConfID);
      } else {
        hooks.run(getLabelState(subConf));
      }
    });

    // add tabs for subconfs that do not exist
    subConfs.forEach(subConf => {
      const subConfID = subConf.name;
      if (!this._subConfHooks.has(subConfID)) {
        this.log(`addSubConf(${subConfID})`);

        const hooks = this._addTab(subConfID, subConf);
        this._subConfHooks.set(subConfID, hooks);
      }
    });

    this._mainConfHooks.run(getLabelState(this._ctrl.mainConfCounts));
  }
}
