import Api from 'Api/Api';
import logger from 'Log/logger';

const log = logger('Lcm:CallCommand');

export default class CallCommand {
  static setSubConf(callID, value, params) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('setSubConf', id, value, params));
  }

  static setMute(callID, value) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('setMute', id, value));
  }

  static setHost(callID, value) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('setHost', id, value));
  }

  static setStar(callID, value) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('setTempDataField', id, 'starred', value));
  }

  static setHold(callID, value) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('setHold', id, value));
  }

  static sendDisconnectCall(callID) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('disconnect', id, 1));
  }

  static adjustGain(callID, value) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => this.send('adjustInGain', id, value));
  }

  static sendToConference(callID, value) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => {
      this.send('disconnect', id, 1, {
        noPrompts : 1,
        status : 700,
        data : {
          confID : value
        }
      });
    });
  }

  static sendToHelpQueue(callID) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => {
      this.send('runCallCmd', id, null, {
        command: 'operatorOn',
        params: {
          noPrompts: 1,
        },
      });
    });
  }

  static removeFromHelpQueue(callID) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => {
      this.send('runCallCmd', id, null, {
        command: 'operatorOff',
        params: {
          noPrompts: 1,
        },
      });
    });
  }

  static sendToEnterQueue(callID) {
    if (!(callID instanceof Array))
      callID = [ callID];

    callID.forEach(id => {
      this.send('disconnect', id, 1, {
        noPrompts : 1,
        status : 700,
      });
    });
  }

  static raisedHandRaise(callID) {
    if (!(callID instanceof Array))
      callID = [ callID ];

    callID.forEach(id => this.send('raiseHand', id, null, {
      noPrompts: 1,
    }));
  }

  static raisedHandLower(callID) {
    if (!(callID instanceof Array))
      callID = [ callID ];

    callID.forEach(id => this.send('lowerHand', id, 1));
  }

  static raisedHandMove(callID, direction, handRaisedIndex = null) {
    const params = handRaisedIndex !== null
      ? { handRaisedIndex }
      : null;
    this.send('raisedHandMove', callID, direction, params);
  }

  static raisedHandSelect(callID) {
    this.send('select', callID, 0);
  }

  static raisedHandSelectCancel(callID, muted) {
    this.send('selectCancel', callID, muted);
  }

  static raisedHandSelectRelease(callID, muted) {
    this.send('selectRelease', callID, muted);
  }

  static send(command, callID, value, params) {
    const msg = `send(${command}, ${callID}, ${value})`;
    const reqParams = {
      command,
      callID,
      value,
    };

    if (params)
      reqParams.params = params;

    Api.get('LCM', 'changeConferenceCall', reqParams)
      .then(() => {
        log(`${msg} - Success`);
      })
      .catch(err => {
        // ignore cancelled
        if (err.cancelled) {
          return;
        }
        log(`${msg} - Error`, err);
      });
  }
}
