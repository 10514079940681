import EventEmitter from 'events';

// the following are all in seconds
const RETRY_INITIAL = 3;
const RETRY_MAX = 60;
const RETRY_MAX_DRIFT = 3;

export default class TaskScheduler extends EventEmitter {
  constructor({ task }) {
    super();
    this._initState();
    this._task = task;
  }

  _initState() {
    this._mainTimer = null;
    this._isRetrying = false;

    this._resetRetry();
  }

  stop() {
    this._stopMainTimer();
    this._stopRetryTimer();

    this._initState();
  }

  scheduleNext(delay = 0, data = null) {
    this._isRetrying = false;

    this._resetRetry();

    this._startMainTimer(delay, data);
  }

  scheduleFailed() {
    this._isRetrying = true;

    this._retryStart();
  }

  _resetRetry() {
    this._retryLast = null;
    this._retryCtr = 0;
  }

  // public retry method
  retry() {
    this._resetRetry();
    this._startMainTimer();

    // force update so display is correct
    this.emit('retryTimer');
  }

  get isRetrying() {
    return this._isRetrying;
  }

  get retryWaitSecs() {
    return this._retryCtr;
  }

  _retryStart() {
    this._stopRetryTimer();

    if (this._retryLast !== null) {
      this._retryLast = Math.min(this._retryLast * 2, RETRY_MAX);
      this._retryCtr = this._retryLast - Math.round(Math.random() * RETRY_MAX_DRIFT);
    } else {
      this._retryLast = this._retryCtr = RETRY_INITIAL;
    }

    this._retryTimer = setInterval(() => {
      this._retryCtr--;

      if (this._retryCtr === 0) {
        this._startMainTimer();
      }

      this.emit('retryTimer');
    }, 1000);

    // emit immediately since there is a 1 second delay before first
    // retryTimer fires
    this.emit('retryTimer');
  }

  _stopRetryTimer() {
    clearInterval(this._retryTimer);
  }

  _stopMainTimer() {
    if (this._mainTimer) {
      clearTimeout(this._mainTimer);
    }
    this._mainTimer = null;
  }

  _startMainTimer(timeout = 0, data = null) {
    this._stopMainTimer();
    this._stopRetryTimer();

    this._mainTimer = setTimeout(() => {
      this._mainTimer = null;
      this._task(data);
    }, timeout);
  }
}
