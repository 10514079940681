import axios from 'axios';

export class ApiError extends Error {
  constructor(code, cause, message = null) {
    super(message || code);
    this.name = 'ApiError';
    this.code = code;
    if (cause) {
      this.cause = cause;
      if (axios.isCancel(cause)) {
        this.cancelled = true;
      }

      this.causeMessage = cause.message;
      this.causeCode = cause.code || null;
    }
  }
}

export class ApiErrorNetwork extends ApiError {
  constructor(err) {
    super('API_NETWORK', err);
    this.name = 'ApiErrorNetwork';
  }
}

export class ApiErrorEnvelope extends ApiError {
  constructor(errorPayload) {
    super('API_ENVELOPE_ERROR');
    this.name = 'ApiErrorEnvelope';
    this.errorPayload = errorPayload;

    if (errorPayload.code.match(/^ERR_API_TOKEN/)) {
      this.tokenError = true;
      this.cancelled = true;
    }
  }
}

export class ApiErrorResult extends ApiError {
  constructor(errorResult) {
    const { code, message, parameterName } = errorResult;
    super(code, null, message);
    this.name = 'ApiErrorResult';
    this.errorResult = errorResult;
    if (parameterName !== undefined) {
      this.parameterName = parameterName;
    }
  }

  isInvalidParamError() {
    return this.errorResult.code.indexOf('ERR_API_REQUEST_PARAMETER_INVALID') === 0;
  }
}
