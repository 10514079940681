export const GAIN_MIN = -15;
export const GAIN_MAX = 15;

export const IR_STATE_PENDING  = 0;
export const IR_STATE_RUNNING  = 1;
export const IR_STATE_RECORDED = 2;
export const IR_STATE_SKIPPED  = 3;

export const ACCESS_METHOD_PSTN            = 0;
export const ACCESS_METHOD_SIP             = 1;
export const ACCESS_METHOD_WEBCALL         = 2;
export const ACCESS_METHOD_SKYPE           = 3;
export const ACCESS_METHOD_OPERATOR        = 4;
export const ACCESS_METHOD_SKYPEBUSINESS   = 5;
export const ACCESS_METHOD_STREAM          = 6;
export const ACCESS_METHOD_BROADCAST       = 7;
