import axios from 'axios';
import EventEmitter from 'events';

import localStorage from 'Browser/localStorage';
import logger from 'Log/logger';

import s from './strings';

const FETCH_CONFIG_TIMEOUT = 500;

export class AppController extends EventEmitter {
  constructor({
    localStorageKey = null,
    storedFields = null,

    alwaysListenOnly = false,
    enableSettings = true,
  }) {
    super();

    this.log = logger('WebCall:AppController');

    this._currentCallController = null;
    this._callControllers = [];

    this._appConfig = null;
    this._formDefaults = {};

    this.localStorageKey = localStorageKey;
    this.storedFields = storedFields;

    this._alwaysListenOnly = alwaysListenOnly;
    this._enableSettings = enableSettings;

    this._debugMode = false;

    this._connectionError = null;

    this._axiosInstance = axios.create({
      transformResponse: data => JSON.parse(data),
      timeout: FETCH_CONFIG_TIMEOUT,
    });

    window.addEventListener('beforeunload', e => {
      if (this.isConnected) {
        e.preventDefault();
        e.returnValue = s.Errors.ERR_WEBCALL_NAVIGATE_AWAY;
      }
    });

    window.addEventListener('pagehide', e => {
      this._callControllers.forEach(callController => {
        if (callController.isConnected) {
          callController.disconnect();
        }
      });
    });
  }

  loadStoredParams() {
    const { localStorageKey } = this;

    if (!(localStorageKey && localStorage[localStorageKey]))
      return;

    let decoded;
    try {
      decoded = JSON.parse(localStorage[localStorageKey]);
    } catch (e) {
    }

    if (decoded && typeof decoded === 'object')
      this.storedFields.forEach(field => {
        if (typeof decoded[field] === 'string')
          this._formDefaults[field] = decoded[field];
      });
  }

  saveStoredParams(formData) {
    if (this.localStorageKey) {
      const fieldsJSON = {};

      this.storedFields.forEach(name => fieldsJSON[name] = formData[name]);

      localStorage[this.localStorageKey] = JSON.stringify(fieldsJSON);
    }

    return formData;
  }

  clearStoredParams() {
    if (this.localStorageKey) {
      delete localStorage[this.localStorageKey];
    }
  }

  clearLocalStorage() {
    Object.keys(localStorage).forEach(key => {
      if (key.indexOf('webCall') !== 0) {
        return;
      }

      this.log(`deleting localStorage key ${key}`);

      delete localStorage[key];
    });
  }

  openSettings() {
    this.callController.openSettings();
  }

  initiateConnection(callController, formData, getConnectParams) {
    this.log('initiateConnection()');

    this._currentCallController = callController;
    this._connectionError = null;

    Promise.resolve()
      .then(() => this.saveStoredParams(formData))
      .then(() => getConnectParams(formData))
      .then(connectParams => this.fetchConfig(connectParams))
      .then(connectParams => callController.setConnectParams(connectParams))
      .then(() => callController.connect(this._alwaysListenOnly))
      .catch(err => this._setConnectionError(err))
      .then(() => {
        this.emit('update');
      });
  }

  addCallController(ctrl) {
    this._callControllers.push(ctrl);
    ctrl.on('error', err => this._setConnectionError(err));
  }

  setAppConfig(appConfig) {
    this._appConfig = appConfig;
  }

  setFormDefaults(formDefaults) {
    this._formDefaults = formDefaults;
  }

  setDebugMode(debugMode) {
    this._debugMode = debugMode;
    this._callControllers.forEach(ctrl => ctrl.setDebugMode(debugMode));
  }

  get isActive() {
    return this._callControllers.some(ctrl => ctrl.isActive);
  }

  get isConnected() {
    return this._callControllers.some(ctrl => ctrl.isConnected);
  }

  get formDefaults() {
    return this._formDefaults;
  }

  get alwaysListenOnly() {
    return this._alwaysListenOnly;
  }

  get enableSettings() {
    return this._enableSettings;
  }

  get debugMode() {
    return this._debugMode;
  }

  fetchConfig(connectParams) {
    return Promise.resolve()
      .then(() => {
        const tasks = [];

        if (connectParams.fetchLocationURL) {
          tasks.push(
            this._fetchLocation(connectParams.fetchLocationURL)
              .then(location => {
                if (location) {
                  connectParams.toSipURI += `;location=${location}`;
                }
              })
          );
        }

        if (connectParams.fetchRTCConfigurationURL) {
          tasks.push(
            this._fetchRTCConfiguration(connectParams.fetchRTCConfigurationURL)
              .then(rtcConfiguration => {
                if (rtcConfiguration) {
                  connectParams.rtcConfiguration = rtcConfiguration;
                }
              })
          );
        }

        return Promise
          .all(tasks)
          .then(() => connectParams);
      });
  }

  _fetchLocation(url) {
    this.log(`_fetchLocation(${url})`);

    return this._axiosInstance(url)
      .then(res => {
        this.log('_fetchLocation() | success', res.data);
        return res && res.data && res.data.RegionCode || null;
      })
      .catch(err => {
        this.log('_fetchLocation() | failure', err);

        // swallow errors
        return null;
      });
  }

  _fetchRTCConfiguration(url) {
    this.log(`_fetchRTCConfiguration(${url})`);

    return this._axiosInstance(url)
      .then(res => {
        this.log('_fetchRTCConfiguration() | success', res.data);
        return res && res.data || null;
      })
      .catch(err => {
        this.log('_fetchRTCConfiguration() | failure', err);

        // swallow errors
        return null;
      });
  }

  get listenOnlyDisabled() {
    if (!this._currentCallController)
      return false;

    return this._currentCallController.listenOnlyDisabled;
  }

  get connectionErrorMessage() {
    if (!this._connectionError)
      return null;

    return this._connectionError.message;
  }

  get connectionErrorType() {
    if (!this._connectionError)
      return null;

    return this._connectionError.name;
  }

  connectionErrorRetry(listenOnly = false) {
    this.log(`connectionErrorRetry() | listenOnly=${listenOnly}`);

    Promise.resolve()
      .then(() => this._currentCallController.connect(listenOnly))
      .then(() => {
        this._connectionError = null;
      })
      .catch(err => this._setConnectionError(err))
      .then(() => {
        this.emit('update');
      });
  }

  connectionErrorDismiss() {
    this._connectionError = null;
    this.emit('update');
  }

  _setConnectionError(err) {
    this.log('_setConnectionError()', err);
    if (err.cause)
      this.log('_setConnectionError() | cause', err.cause);

    this._connectionError = err;
  }
}
