export class LoadError extends Error {
  constructor(message = '', details = null) {
    super(message);
    this.name = 'LoadError';
    if (details) {
      this.details = details;
    }
  }
}

export class NotFoundError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'NotFoundError';
  }
}

export class InvalidRequestError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'InvalidRequestError';
  }
}

export class AuthError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'AuthError';
  }
}
