function toggle(node, flag) {
  if (flag) {
    node.removeAttribute('hidden');
  } else {
    node.setAttribute('hidden', 'hidden');
  }
}

export function hook(el, rval) {
  const reg = rval();
  if (reg === false || reg === null) {
    // do nothing. this is to support conditional hooks like:
    // use:hook={shouldAddShowHook && hooks.show('showProperty')}
    return;
  }
  if (typeof reg !== 'function') {
    throw new Error('hook(): rval returned non-function');
  }

  reg(el);
}

export class Hooks {
  constructor() {
    this._hooks = {};
    this._hooksForced = {};
  }

  add(field, fn) {
    if (!field) return;

    if (field.charAt(0) === '!') {
      const field2 = field.substring(1);

      if (field2 in this._hooksForced) {
        this._hooksForced[field2].push(fn);
      } else {
        this._hooksForced[field2] = [ fn ];
      }

      return;
    }

    if (field in this._hooks) {
      this._hooks[field].fns.push(fn);
    } else {
      this._hooks[field] = {
        curVal: undefined,
        fns: [ fn ],
      };
    }
  }

  run(data) {
    for (const [ field, def ] of Object.entries(this._hooks)) {
      const newVal = data[field];
      if (newVal === undefined) {
        setTimeout(() => {
          throw new Error(`Hooks.run() got undefined for: ${field}`);
        }, 0);
      }

      if (def.curVal === newVal) continue;

      def.curVal = newVal;

      def.fns.forEach(fn => fn(newVal));
    }

    for (const [ field, fns ] of Object.entries(this._hooksForced)) {
      const newVal = data[field];
      if (newVal === undefined) {
        setTimeout(() => {
          throw new Error(`Hooks.run() got undefined for: ${field}`);
        }, 0);
      }

      fns.forEach(fn => fn(newVal));
    }
  }

  text(field, transform = null) {
    return el => {
      this.add(field, val => {
        el.textContent = transform ? transform(val) : val;
      });
    };
  }

  textContent(field, transform = null) {
    return this.text(field, transform);
  }

  prop(field, propName, transform = null) {
    return el => {
      this.add(field, val => {
        el[propName] = transform ? transform(val) : val;
      });
    };
  }

  attr(field, attrName, transform = null, toggle = false) {
    return el => {
      if (toggle) {
        this.add(field, val => {
          el.toggleAttribute(attrName, transform ? transform(val) : val);
        });
        return;
      }

      this.add(field, val => {
        el.setAttribute(attrName, transform ? transform(val) : val);
      });
    };
  }

  show(field, transform = null) {
    return el => {
      this.add(field, val => {
        toggle(el, transform ? transform(val) : val);
      });
    };
  }

  hide(field, transform = null) {
    return el => {
      this.add(field, val => {
        toggle(el, !(transform ? transform(val) : val));
      });
    };
  }

  showEq(field, eqVal) {
    return el => {
      this.add(field, val => {
        toggle(el, val === eqVal);
      });
    };
  }

  hideEq(field, eqVal) {
    return el => {
      this.add(field, val => {
        toggle(el, val !== eqVal);
      });
    };
  }

  toggleClass(field, className, transform = null) {
    return el => {
      this.add(field, val => {
        el.classList.toggle(className, transform ? transform(val) : val);
      });
    };
  }
}
